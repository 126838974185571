// WidgetRentalPointDialogV4.js
import React, { useMemo } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import UserData from "../WidgetsProfile/UserData/UserData";
import WidgetBikeItemDataV4 from "./WidgetBikeItemDataV4";
import WidgetBikesReplacmentListV4 from "./WidgetBikesReplacmentListV4";
import WidgetWoucherListV4 from "./WidgetWoucherListV4";

// Стили сообщений
const messageStyles = {
  borderRadius: "16px",
  padding: "16px",
  boxSizing: "border-box",
};

const leftMessageStyle = {
  ...messageStyles,
  borderRadius: "16px 0 16px 16px",
  backgroundColor: "var(--color-gray-100)",
};

const rightMessageStyle = {
  ...messageStyles,
  borderRadius: "0 16px 16px 16px",
  backgroundColor: "var(--color-gray-100)",
};

const canceledMessageStyle = {
  ...leftMessageStyle,
  backgroundColor: "var(--color-alarm)",
  color: "var(--color-white)",
};

const dialogContainerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
};

const itemsListStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "2px",
};

const voucherContainerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  borderRadius: "16px",
};

const WidgetRentalPointDialogV4 = ({
  // user
  userName,
  userRole,
  userAvatarUrls,
  // rentalPoint
  rentalPointName,
  rentalPointLogoFile,
  rentalPointRootData,
  // order
  currentCart,
  totalOrders,
  replacementBox,
  waitUserBox,
  order_selectedCompletedRootOrder,
  handleReplacementBike,
  handleDeleteReplacementBike,
  currentStateOrder,
  rootOrderStatus,
  // Params
  isMobile,
}) => {
  // Оптимизированная функция поиска статусов
  const findStatus = useMemo(() => {
    return (statusToFind) => {
      const foundItem = rootOrderStatus?.find(
        (item) => item.status === statusToFind
      );
      return foundItem
        ? {
            status: true,
            userId: foundItem?.user?.userId,
            timestamp: foundItem?.timestamp,
            userName: foundItem?.user?.userName,
            userAvatarUrls: foundItem?.user?.userAvatarUrls,
          }
        : { status: false, userId: null, timestamp: 0 };
    };
  }, [rootOrderStatus]);

  // Получаем статусы
  const replacementStatus = findStatus("replacement");
  const confirmStatus = findStatus("confirm");
  const canceledStatus = findStatus("canceled");

  return (
    <ContentBox position="start">
      {/* Новый заказ от клиента */}
      <UserData
        type="left"
        date={canceledStatus.timestamp}
        userName={canceledStatus.userName}
        userAvatarUrls={canceledStatus.userAvatarUrls}
        isMobile={isMobile}
      >
        <div style={dialogContainerStyle}>
          <div style={leftMessageStyle}>
            <p className="p-light">Новый заказ от клиента</p>
          </div>

          <div style={itemsListStyle}>
            {currentCart?.map((item, index) => (
              <div key={`cart-item-${index}`} className="list-item">
                <WidgetBikeItemDataV4
                  item={item}
                  index={index}
                  groupItems={currentCart}
                  isMobile={isMobile}
                />
              </div>
            ))}
          </div>
        </div>
      </UserData>

      {/* Предложение замены */}
      {(replacementBox || replacementStatus.status) && (
        <UserData
          type="right"
          date={replacementStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div style={dialogContainerStyle}>
            <div style={rightMessageStyle}>
              <p className="p-light">Выберите варианты замены</p>
            </div>

            {currentCart?.map((item, index) => (
              <div key={`replacement-${index}`} style={itemsListStyle}>
                <WidgetBikesReplacmentListV4
                  item={item}
                  indexBikeCurrentCart={index}
                  order_selectedCompletedRootOrder={
                    order_selectedCompletedRootOrder
                  }
                  onClick={handleReplacementBike}
                  onClickDelete={handleDeleteReplacementBike}
                  isMobile={isMobile}
                  currentStateOrder={currentStateOrder}
                />
              </div>
            ))}
          </div>
        </UserData>
      )}

      {/* Ожидание ответа клиента */}
      {(waitUserBox || replacementStatus.status) && (
        <UserData
          type="left"
          date={replacementStatus.timestamp}
          userName={userName}
          userAvatarUrls={userAvatarUrls}
          isMobile={isMobile}
        >
          <div style={leftMessageStyle}>
            <p className="p-light">Ожидаем ответа от клиента</p>
          </div>
        </UserData>
      )}

      {/* Подтверждение заказа */}
      {confirmStatus.status && (
        <UserData
          type="right"
          date={confirmStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div style={dialogContainerStyle}>
            <div style={rightMessageStyle}>
              <p className="p-light">Клиент подтвердил заказ</p>
            </div>

            <div style={voucherContainerStyle}>
              <WidgetWoucherListV4
                isMobile={isMobile}
                userType={userRole}
                currentRentalPointOrders={currentCart}
                totalOrders={totalOrders}
                rentalPointRootData={rentalPointRootData}
              />
            </div>
          </div>
        </UserData>
      )}

      {/* Отмена заказа */}
      {canceledStatus.status && (
        <UserData
          type="left"
          date={canceledStatus.timestamp}
          userName={canceledStatus.userName}
          userAvatarUrls={canceledStatus.userAvatarUrls}
          isMobile={isMobile}
        >
          <div style={canceledMessageStyle}>
            <p className="p-light" style={{ color: "var(--color-white)" }}>
              Клиент отменил заказ
            </p>
          </div>
        </UserData>
      )}
    </ContentBox>
  );
};

export default WidgetRentalPointDialogV4;

// import React from "react";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import UserData from "../WidgetsProfile/UserData/UserData";
// import WidgetBikeItemDataV4 from "./WidgetBikeItemDataV4";
// import WidgetBikesReplacmentListV4 from "./WidgetBikesReplacmentListV4";
// import WidgetWoucherListV4 from "./WidgetWoucherListV4";
// import { v4 as uuidv4 } from "uuid";

// const WidgetRentalPointDialogV4 = ({
//   // user
//   userName,
//   userRole,
//   userAvatarUrls,
//   // rentalPoint
//   rentalPointName,
//   rentalPointLogoFile,
//   rentalPointRootData,
//   // order
//   currentCart,
//   totalOrders,
//   replacementBox,
//   waitUserBox,
//   order_selectedCompletedRootOrder,
//   handleReplacementBike,
//   handleDeleteReplacementBike,
//   currentStateOrder,
//   rootOrderStatus,
//   // Params
//   isMobile,
// }) => {
//   const findStatus = (statusToFind) => {
//     // Ищем элемент с нужным статусом
//     const foundItem = rootOrderStatus?.find(
//       (item) => item.status === statusToFind
//     );
//     console.log("foundItem", foundItem);
//     // Если элемент найден, возвращаем true и userId
//     if (foundItem) {
//       return {
//         status: true,
//         userId: foundItem?.user?.userId,
//         timestamp: foundItem?.timestamp,
//         userName: foundItem?.userName,
//       };
//     }

//     // Если элемент не найден, возвращаем false
//     return { exists: false, userId: null, timestamp: 0 };
//   };

//   return (
//     <ContentBox position="start">
//       <>
//         <UserData
//           type="left"
//           date={findStatus("new").timestamp || 0}
//           userName={userName}
//           userAvatarUrls={userAvatarUrls}
//           isMobile={isMobile}
//         >
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               gap: "8px",
//             }}
//           >
//             {/* message */}
//             <div
//               style={{
//                 width: "100%",
//                 backgroundColor: "var(--color-gray-100)",
//                 borderRadius: "16px 0 16px 16px",
//                 padding: "16px",
//                 boxSizing: "border-box",
//               }}
//             >
//               <p className="p-light">Новый заказ от клиента</p>
//             </div>
//             {/* order data */}
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               {currentCart?.map((item, index) => (
//                 <div
//                   key={uuidv4()}
//                   style={{
//                     width: "100%",
//                   }}
//                 >
//                   <WidgetBikeItemDataV4
//                     item={item}
//                     index={index}
//                     groupItems={currentCart}
//                     isMobile={isMobile}
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </UserData>
//         {/* Замена заказа */}
//         {(replacementBox || findStatus("replacement")?.status) && (
//           <UserData
//             type="right"
//             date={findStatus("replacement")?.timestamp || 0}
//             userName={rentalPointName}
//             userAvatarUrls={rentalPointLogoFile}
//             isMobile={isMobile}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               <div
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-100)",
//                   borderRadius: "0 16px 16px 16px",
//                   padding: "16px",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 <p className="p-light">Выберите варианты замены</p>
//               </div>

//               {currentCart?.map((item, index) => (
//                 <div
//                   key={uuidv4()}
//                   style={{
//                     width: "100%",
//                   }}
//                 >
//                   <WidgetBikesReplacmentListV4
//                     item={item}
//                     indexBikeCurrentCart={index}
//                     order_selectedCompletedRootOrder={
//                       order_selectedCompletedRootOrder
//                     }
//                     onClick={handleReplacementBike}
//                     onClickDelete={handleDeleteReplacementBike}
//                     isMobile={isMobile}
//                     currentStateOrder={currentStateOrder}
//                   />
//                 </div>
//               ))}
//             </div>
//           </UserData>
//         )}
//         {/* Ожидание ответа от клиента */}
//         {(waitUserBox || findStatus("replacement")?.status) && (
//           <UserData
//             type="left"
//             date={findStatus("replacement").timestamp || 0}
//             userName={userName}
//             userAvatarUrls={userAvatarUrls}
//             isMobile={isMobile}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               <div
//                 style={{
//                   width: "100%",
//                   // aspectRatio: "16/9",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "flrx-start",
//                   justifyContent: "center",
//                   gap: "16px",
//                   backgroundColor: "var(--color-gray-100)",
//                   borderRadius: "16px 0 16px 16px",
//                   padding: "16px",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 <p className="p-light">Ожидаем ответа от клиента</p>
//               </div>
//             </div>
//           </UserData>
//         )}

//         {/* Ваучер для ТОЧКИ ПРОКАТА */}
//         {findStatus("confirm")?.status && (
//           <UserData
//             type="right"
//             date={findStatus("confirm").timestamp || 0}
//             userName={rentalPointName}
//             userAvatarUrls={rentalPointLogoFile}
//             isMobile={isMobile}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               {/* message */}

//               <div
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-100)",
//                   borderRadius: "0 16px 16px 16px",
//                   padding: "16px",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 <p className="p-light">Клиент подтвердил заказ</p>
//               </div>

//               {/* wuocher */}
//               <div
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   gap: "2px",
//                   borderRadius: "16px",
//                 }}
//               >
//                 <WidgetWoucherListV4
//                   isMobile={isMobile}
//                   userType={userRole}
//                   currentRentalPointOrders={currentCart}
//                   totalOrders={totalOrders}
//                 />
//               </div>
//             </div>
//           </UserData>
//         )}
//         {/* Заказ отменен КЛИЕНТОМ */}
//         {findStatus("canceled")?.status && (
//           <UserData
//             type="left"
//             date={findStatus("canceled").timestamp || 0}
//             isMobile={isMobile}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "8px",
//               }}
//             >
//               <div
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-alarm)",
//                   borderRadius: "16px 0 16px 16px",
//                   padding: "16px",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 {/* <span className="material-symbols-outlined">close</span> */}
//                 <p
//                   className="p-light"
//                   style={{
//                     color: "var(--color-white)",
//                   }}
//                 >
//                   Клиент отменил заказ
//                 </p>
//               </div>
//             </div>
//           </UserData>
//         )}
//       </>
//     </ContentBox>
//   );
// };
// export default WidgetRentalPointDialogV4;
