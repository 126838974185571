// import React, { useState, useRef, useEffect } from "react";

// const CarouselMenu = ({ options, onClick, height }) => {
//   const [currentIndex, setCurrentIndex] = useState(0); // Индекс текущего элемента
//   const containerRef = useRef(null); // Ref для контейнера с элементами
//   const [totalWidth, setTotalWidth] = useState(0); // Суммарная ширина всех элементов

//   // Вычисляем ширину всех элементов при загрузке
//   useEffect(() => {
//     if (containerRef.current) {
//       const children = containerRef.current.children;
//       let width = 0;
//       for (let child of children) {
//         width += child.getBoundingClientRect().width;
//       }
//       setTotalWidth(width);
//     }
//   }, [options]); // Пересчитываем, если `options` изменится

//   const handleNext = () => {
//     if (currentIndex < options.length - 1) {
//       const nextIndex = currentIndex + 1;
//       setCurrentIndex(nextIndex);
//       document.getElementById(nextIndex.toString()).scrollIntoView({
//         behavior: "smooth",
//         block: "nearest",
//         inline: "start",
//       });
//     }
//   };

//   const handlePrev = () => {
//     if (currentIndex > 0) {
//       const prevIndex = currentIndex - 1;
//       setCurrentIndex(prevIndex);
//       document.getElementById(prevIndex.toString()).scrollIntoView({
//         behavior: "smooth",
//         block: "nearest",
//         inline: "start",
//       });
//     }
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         position: "relative",
//         overflow: "hidden",
//         width: "100%",
//         backgroundColor: "var(--color-white)",
//         padding: "8px",
//         boxSizing: "border-box",
//         // height: height,
//       }}
//     >
//       <span
//         onClick={handlePrev}
//         className="material-symbols-outlined"
//         style={{
//           cursor: "pointer",
//           zIndex: 1,
//           position: "absolute",
//           left: "0px",
//         }}
//       >
//         keyboard_arrow_left
//       </span>

//       {/* <div
//         style={{
//           position: "absolute",
//           height: "100%",
//           aspectRatio: "1/3",
//           background:
//             "linear-gradient(to right, white, rgba(255, 255, 255, 0))",
//           left: "24px",
//         }}
//       /> */}

//       <div
//         ref={containerRef}
//         style={{
//           width: "100%",
//           // height: `${height - 8}px`,
//           display: "flex",
//           gap: "8px",
//           overflowX: "auto",
//           overflowY: "hidden",
//           scrollSnapType: "x mandatory",
//           boxSizing: "border-box",
//           msOverflowStyle: "none", // Для IE и Edge
//           scrollbarWidth: "none", // Для Firefox
//           // backgroundColor: "violet",
//         }}
//       >
//         {options.map((option, index) => (
//           <div
//             onClick={option.onClick}
//             key={index}
//             id={index.toString()}
//             style={{
//               flexShrink: 0,
//               height: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               gap: "16px",
//               // borderRadius: "80px",
//               // backgroundColor: "var(--color-gray-100)",
//               scrollSnapAlign: "start",
//               marginLeft: index === 0 ? "16px" : "0",
//               marginRight: index === options.length - 1 ? "16px" : "0",
//             }}
//           >
//             {option.item}
//           </div>
//         ))}
//       </div>

//       {/* <div
//         style={{
//           position: "absolute",
//           height: "100%",
//           aspectRatio: "1/3",
//           background: "linear-gradient(to left, white, rgba(255, 255, 255, 0))",
//           right: "24px",
//         }}
//       /> */}

//       <span
//         onClick={handleNext}
//         className="material-symbols-outlined"
//         style={{
//           cursor: "pointer",
//           zIndex: 1,
//           position: "absolute",
//           right: "0px",
//         }}
//       >
//         keyboard_arrow_right
//       </span>
//     </div>
//   );
// };

// export default CarouselMenu;

import React, { useState, useRef, useEffect } from "react";
import useIsMobile from "../../../../../../functions/isMobile";

const CarouselMenu = ({ options, padding }) => {
  const isMobile = useIsMobile();

  const [currentIndex, setCurrentIndex] = useState(0); // Индекс текущего элемента
  const containerRef = useRef(null); // Ref для контейнера с элементами
  const [totalWidth, setTotalWidth] = useState(0); // Суммарная ширина всех элементов

  // Вычисляем ширину всех элементов при загрузке
  useEffect(() => {
    if (containerRef.current) {
      const children = containerRef.current.children;
      let width = 0;
      for (let child of children) {
        width += child.getBoundingClientRect().width;
      }
      setTotalWidth(width);
    }
  }, [options]); // Пересчитываем, если `options` изменится

  const handleNext = () => {
    if (currentIndex < options.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      document.getElementById(nextIndex.toString()).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      document.getElementById(prevIndex.toString()).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        padding: "8px 0",
        boxSizing: "border-box",
        backgroundColor: "var(--color-white)",
      }}
    >
      <button
        onClick={handlePrev}
        style={{
          cursor: "pointer",
          border: "0",
          zIndex: "1",
          left: "16px",
          position: "absolute",
          backgroundColor: "var(--color-white)",
          borderRadius: "80px",
          display: window.innerWidth <= 400 ? "none" : "inline-block", // Скрываем на мобильных
        }}
        className="material-symbols-outlined"
      >
        keyboard_arrow_left
      </button>

      <div
        style={{
          overflowX: "auto",
          overflowY: "hidden",
          scrollSnapType: "x mandatory",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: padding,
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          boxSizing: "border-box",
        }}
        ref={containerRef}
      >
        <style>{`.box::-webkit-scrollbar {display: none;}`}</style>

        {options.map((option, index) => (
          <div
            onClick={option.onClick}
            key={index}
            id={index.toString()}
            style={{
              flexShrink: 0,
              scrollSnapAlign: "start",
              padding: "4px",
              paddingLeft: index === 0 ? "24px" : "0",
              paddingRight: index === options.length - 1 ? "24px" : "0",
            }}
          >
            <>{option.item}</>
          </div>
        ))}
      </div>

      <button
        onClick={handleNext}
        style={{
          cursor: "pointer",
          border: "0",
          zIndex: "1",
          right: "16px",
          position: "absolute",
          backgroundColor: "var(--color-white)",
          borderRadius: "80px",
          display: window.innerWidth <= 400 ? "none" : "inline-block",
        }}
        className="material-symbols-outlined"
      >
        keyboard_arrow_right
      </button>
    </div>
  );
};

export default CarouselMenu;
