import React, { useState, useCallback } from "react";
import Button from "../Buttons/Button";
import "./uploadFiles.css";
import Preloader from "../../../../../UIElements/Preloader";
import ButtonsBar from "../ButtonsBar/ButtonsBar";

const WindowFileDetailV4 = ({
  onPriorityUpdate,
  onDeleteFile,
  currentFile,
  filesList,
  collectionName,
  documentId,
  fieldName,
  onClose,
  mode,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePriority = async () => {
    setIsLoading(true);
    try {
      // if (
      //   !currentFile ||
      //   !Array.isArray(filesList) ||
      //   !collectionName ||
      //   !documentId ||
      //   !fieldName
      // ) {
      //   throw new Error("Недостаточно данных для обновления приоритета");
      // }

      await onPriorityUpdate({
        currentFile,
        filesList,
        collectionName,
        documentId,
        fieldName,
      });

      console.log("Priority updated successfully");
    } catch (error) {
      console.error("Error updating priority:", error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleDeleteFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const fileIds = [currentFile.id];

      await onDeleteFile({
        collectionName,
        documentId,
        fieldName,
        fileIds,
      });
      console.log("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  }, [
    currentFile,
    onDeleteFile,
    collectionName,
    documentId,
    fieldName,
    onClose,
  ]);

  return (
    <>
      <div
        style={{
          // width: "100%",
          // overflow: "hidden",
          // borderRadius: "0 0 24px 24px",
          ...((mode === "edit" || mode === "new") && { marginBottom: "72px" }),
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              left: "0",
              right: "0",
              top: "0",
              bottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "var(--color-gray-100-alpha-50)",
              boxSizing: "border-box",
            }}
          >
            <Preloader />
          </div>
        )}

        {currentFile && (
          <img
            src={currentFile.url}
            alt="Current File"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              ...((mode === "edit" || mode === "new") && {
                borderRadius: "0 0 24px 24px",
                boxSizing: "border-box",
              }),
            }}
          />
        )}
      </div>

      {(mode === "edit" || mode === "new") && (
        <ButtonsBar>
          <div
            style={{
              display: "grid",
              gap: "8px",
              ...(mode === "new" && { gridTemplateColumns: "repeat(1, 1fr)" }),
              ...(mode === "edit" && { gridTemplateColumns: "repeat(2, 1fr)" }),
            }}
          >
            <Button
              type="small"
              active={!isLoading && !currentFile?.priority}
              label={
                currentFile?.priority ? "Start photo" : "Set as start photo"
              }
              icon={
                currentFile?.priority ? (
                  <span className="material-symbols-outlined">task_alt</span>
                ) : (
                  <span className="material-symbols-outlined">
                    radio_button_unchecked
                  </span>
                )
              }
              color="--color-primary"
              onClick={handlePriority}
            />
            {mode === "edit" && (
              <Button
                type="small"
                active={!isLoading}
                label="Delete"
                allert={
                  <div
                    style={{
                      padding: "32px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Please confirm that you really want to delete this file.
                    </h1>
                    <p className="p-light">
                      This action is irreversible, and you will not be able to
                      recover it.
                    </p>
                  </div>
                }
                icon={<span className="material-symbols-outlined">delete</span>}
                color="--color-alarm"
                onClick={handleDeleteFiles}
              />
            )}
          </div>
        </ButtonsBar>
      )}
    </>
  );
  // return(
  //   <img
  //           src={currentFile.url}
  //           alt="Current File"
  //           style={{ width: "100%", height: "auto" }}
  //         />
  // )
};

export default WindowFileDetailV4;
