import React from "react";
import "./addData.css";
import Preloader from "../../../../../UIElements/Preloader";
const AddData = ({
  isLoading,
  icon,
  title,
  description,
  onClick,
  children,
}) => {
  return (
    <div className="addData">
      <div className="addData__content-box">
        <div
          className="addData_main-icon-box"
          style={{ cursor: !isLoading ? "pointer" : "default" }}
          onClick={isLoading ? null : onClick}
        >
          {isLoading ? <Preloader /> : icon}
          {/* {icon} */}
        </div>

        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "4px",
            // backgroundColor: "violet",
          }}
        >
          <h6>{title}</h6>
          <p className="p-light">{description}</p>
        </div>
      </div>

      {/* <div
        style={{
          visibility: "hidden",
        }}
      >
        <span className="material-symbols-outlined">chevron_right</span>
      </div> */}
      {children}
    </div>
  );
};

export default AddData;
