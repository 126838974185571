import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetViewBikeDetail from "./WidgetViewBikeDetail";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";
import { v4 as uuidv4 } from "uuid";
import priorityFile from "../../../../../functions/priorityFile";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/catalogSliceV4";
import {
  subscribeRootDocumentsThunkV4,
  unsubscribeRootDocument,
} from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribeRootDocumentsThunkV4";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderDetailModeration from "../OrdersV3/WindowOrderDetailModeration";
import WidgetAlert from "../WidgetsProfile/Alerts/WidgetAlert";
const truncateToThreeChars = (value) => {
  // Проверяем, является ли value строкой
  if (typeof value === "string") {
    return value.slice(0, 3); // Обрезаем строку до 3 символов
  }
  return value; // Возвращаем значение без изменений, если это не строка
};

function updateUserChoiceFlag({
  order_selectedCompletedRootOrder,
  indexBikeCurrentCart,
  keySlotReplacementBike,
  indexReplacementBikes,
  flag,
  oneOnly,
}) {
  // Проверяем наличие текущей корзины и других вложенных объектов

  if (
    !order_selectedCompletedRootOrder?.currentCart ||
    !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart] ||
    !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart]
      .replacementBikes ||
    !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart]
      .replacementBikes[keySlotReplacementBike]
  ) {
    console.error("Некорректные данные или структура объекта");
    return null;
  }

  // Создаем копию order_selectedCompletedRootOrder для предотвращения мутации исходного объекта
  const updatedOrder = JSON.parse(
    JSON.stringify(order_selectedCompletedRootOrder)
  );

  // Получаем ссылку на массив по ключу keySlotReplacementBike
  const replacementBikes =
    updatedOrder.currentCart[indexBikeCurrentCart].replacementBikes[
      keySlotReplacementBike
    ];

  // Проверяем существование элемента в массиве по индексу indexReplacementBikes
  if (!replacementBikes[indexReplacementBikes]) {
    console.error("Элемент с указанным индексом не найден в replacementBikes");
    return null;
  }

  // Если oneOnly === true, сбрасываем все флаги userChoice в false
  if (oneOnly) {
    replacementBikes.forEach((bike) => {
      bike.userChoice = false; // сбрасываем все флаги
    });
  }

  // Устанавливаем флаг userChoice в flag для указанного элемента
  replacementBikes[indexReplacementBikes].userChoice = flag;

  // Возвращаем обновленный объект
  return updatedOrder;
}

const updateBikeSlot = ({
  currentSlotIds,
  selectedBike_rootData,
  order_selectedCompletedRootOrder,
  keySlotReplacementBike,
}) => {
  console.log(
    "WindowBikeDetailModerationV4 ***100",
    "currentSlotIds - ",
    currentSlotIds,
    "selectedBike_rootData - ",
    selectedBike_rootData,
    "order_selectedCompletedRootOrder - ",
    order_selectedCompletedRootOrder,
    "keySlotReplacementBike - ",
    keySlotReplacementBike
  );

  const { currentCart } = order_selectedCompletedRootOrder;

  // Проверяем индекс
  console.log(
    "replacement updateBikeSlot***** 200",
    currentSlotIds?.indexBikeCurrentCart
  );

  // Находим cartItem по индексу
  const cartItem = currentCart[currentSlotIds?.indexBikeCurrentCart];

  // Логируем найденный cartItem
  console.log("replacement updateBikeSlot***** 300", cartItem);

  // Проверяем, что replacementBikes существует
  if (cartItem?.replacementBikes) {
    console.log(
      "replacement updateBikeSlot***** 400 - replacementBikes:",
      cartItem?.replacementBikes
    );

    // Проверяем, что ключ с нужным индексом существует в replacementBikes
    if (cartItem?.replacementBikes.hasOwnProperty(keySlotReplacementBike)) {
      const replacementArray =
        cartItem.replacementBikes[keySlotReplacementBike];
      console.log(
        "replacement updateBikeSlot***** 500 - replacementBikes[currentSlotIds]:",
        replacementArray
      );

      // Создаем новый объект с обновленным массивом, добавляя selectedBike_rootData
      const updatedReplacementArray = [
        ...replacementArray,
        selectedBike_rootData,
      ];
      console.log(
        "replacement updateBikeSlot***** 600 - После добавления в массив:",
        updatedReplacementArray
      );

      // Обновляем cartItem с новым массивом replacementBikes
      const updatedCartItem = {
        ...cartItem,
        replacementBikes: {
          ...cartItem.replacementBikes,
          [keySlotReplacementBike]: updatedReplacementArray,
        },
      };

      // Обновляем currentCart с измененным cartItem
      const updatedCurrentCart = [
        ...currentCart.slice(0, currentSlotIds.indexBikeCurrentCart),
        updatedCartItem,
        ...currentCart.slice(currentSlotIds.indexBikeCurrentCart + 1),
      ];

      console.log("replacement updateBikeSlot***** 700:", {
        ...order_selectedCompletedRootOrder,
        currentCart: updatedCurrentCart,
      });

      // Возвращаем новый объект с обновленным currentCart
      return {
        ...order_selectedCompletedRootOrder,
        currentCart: updatedCurrentCart,
      };
    } else {
      console.log(
        "replacement updateBikeSlot***** 500 - Нет такого ключа в replacementBikes"
      );
    }
  } else {
    console.log(
      "replacement updateBikeSlot***** 400 - replacementBikes не существует"
    );
  }

  return order_selectedCompletedRootOrder;
};

const WindowViewBikeDetail = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const {
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    loading,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  // tempPreviewData
  const [idRootDocumentBike, setidRootDocumentBike] = useState(null);
  // tempRootData

  const [photoFiles, setPhotoFiles] = useState(null);
  const [basicInformation, setBasicInformation] = useState(null);
  const [replacementBike, setReplacementBike] = useState(null);
  const [directory, setDirectory] = useState(null);
  // const [orderDeteInterval, setOrderDeteInterval] = useState([0, 0]);
  const [rate, setRate] = useState(null);
  const [startBookingDates, setStartBookingDates] = useState(0);
  const [endBookingDates, setEndBookingDates] = useState(0);

  const [indexBikeCurrentCart, setIndexBikeCurrentCart] = useState(null);
  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);
  const [indexReplacementBikes, setIndexReplacementBikes] = useState(0);
  const [keySlotReplacementBike, setKeySlotReplacementBike] = useState(null);
  const [userChoice, setUserChoice] = useState(false);

  const [selectedBike_rootData, setSelectedBike_rootData] = useState(null);
  const [idRootDocumentRentalPoint, setIdRootDocumentRentalPoint] =
    useState(null);
  const [rentalPointRootData, setRentalPointRootData] = useState(null);
  // const [loading_RentalPoints, setLoading_RentalPoints] = useState(false);
  const [windowOrderDetailModeration, setWindowOrderDetailModeration] =
    useState(false);
  const [orderedBike, setOrderedBike] = useState(null);
  const [orderedBikeTitle, setOrderedBikeTitle] = useState(null);

  // tempData
  const [currentSlotIds, setCurrentSlotIds] = useState(null);

  console.log(
    "selectedRentalPoint_rootData 100",
    orderedBike,
    orderedBikeTitle
  );
  /**
   *
   * Наполнение useState
   *
   * */

  // tempRootData
  useEffect(() => {
    if (mode === "replacement") {
      const startBookingDates =
        tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
          tempData?.currentSlotIds?.indexBikeCurrentCart
        ]?.totals?.dateInterval[0];

      setStartBookingDates(startBookingDates);

      const endBookingDates =
        tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
          tempData?.currentSlotIds?.indexBikeCurrentCart
        ]?.totals?.dateInterval[1];

      setEndBookingDates(endBookingDates);
    }

    setPhotoFiles(tempRootData?.selectedBike_rootData?.files?.photoFiles || []);

    setBasicInformation(
      tempRootData?.selectedBike_rootData?.basicInformation || null
    );
    setReplacementBike(
      tempRootData?.selectedBike_rootData?.basicInformation?.title
        ?.titleOriginal || null
    );

    setDirectory(tempRootData?.selectedBike_rootData?.directory || null);

    setRate(tempRootData?.selectedBike_rootData?.rate || null);

    setRentalPointRootData(tempRootData?.selectedRentalPoint_rootData || null);

    setOrder_selectedCompletedRootOrder(
      tempRootData?.order_selectedCompletedRootOrder || null
    );
    setOrderedBike(
      tempRootData?.order_selectedCompletedRootOrder.currentCart[
        indexBikeCurrentCart
      ]?.bikeData || null
    );
    setOrderedBikeTitle(
      tempRootData?.order_selectedCompletedRootOrder.currentCart[
        indexBikeCurrentCart
      ]?.bikeData?.basicInformation?.title?.titleOriginal || null
    );
    setUserChoice(
      tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
        tempData?.currentSlotIds?.indexBikeCurrentCart
      ]?.replacementBikes?.[keySlotReplacementBike]?.[indexReplacementBikes]
        ?.userChoice || false
    );

    setSelectedBike_rootData(tempRootData?.selectedBike_rootData || {});
  }, [
    tempRootData?.selectedBike_rootData,
    tempRootData?.selectedRentalPoint_rootData,
    tempRootData?.order_selectedCompletedRootOrder,
    indexBikeCurrentCart,
    indexReplacementBikes,
    keySlotReplacementBike,
    tempData?.currentSlotIds?.indexBikeCurrentCart,
    mode,
  ]);

  // tempRootData
  useEffect(() => {
    if (mode === "order") {
      setidRootDocumentBike(
        tempPreviewData?.choosingBikeForOrder_previewData?.idRootDocument ||
          null
      );

      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingBikeForOrder_previewData
          ?.idRootDocumentRentalPoint || null
      );
    } else if (mode === "replacement") {
      setidRootDocumentBike(
        tempPreviewData?.choosingBikeToCreateReplacement_previewData
          ?.idRootDocument || null
      );

      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingBikeToCreateReplacement_previewData
          ?.idRootDocumentRentalPoint || null
      );
    }
  }, [
    mode,
    tempPreviewData?.choosingBikeForOrder_previewData,
    tempPreviewData?.choosingBikeToCreateReplacement_previewData,
  ]);

  // tempData
  useEffect(() => {
    if (mode === "order") {
      setStartBookingDates(tempData?.manage_fdiltersBikes.dateInterval[0]);

      setEndBookingDates(tempData?.manage_fdiltersBikes.dateInterval[1]);
    }
    setCurrentSlotIds(tempData?.currentSlotIds || null);

    setKeySlotReplacementBike(
      tempData?.currentSlotIds?.keySlotReplacementBike || null
    );

    setIndexReplacementBikes(
      tempData?.currentSlotIds?.indexReplacementBike || 0
    );

    setIndexBikeCurrentCart(
      tempData?.currentSlotIds?.indexBikeCurrentCart || 0
    );
  }, [
    tempData?.currentSlotIds,
    mode,
    tempData?.manage_fdiltersBikes.dateInterval,
  ]);

  /**
   *
   * Fetch функции
   *
   */

  // bikes
  useEffect(() => {
    if (
      (mode === "moderation" || mode === "replacement" || mode === "order") &&
      idRootDocumentBike
    ) {
      console.log(`Выполняем подписку на документ: ${idRootDocumentBike}`);

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV4({
              collectionPath: "bikes",
              rootCurrentDocumenState: "selectedBike_rootData",
              idRootDocument: idRootDocumentBike,
              loadingStateName: "loading_bike",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписываемся при размонтировании компонента или изменении `idRootDocumentBike`
      return () => {
        unsubscribeRootDocument(idRootDocumentBike);
      };
    }
  }, [dispatch, mode, idRootDocumentBike]);
  // rentalPoint
  useEffect(() => {
    if (
      (mode === "moderation" || mode === "replacement" || mode === "order") &&
      idRootDocumentRentalPoint
    ) {
      console.log(
        `Выполняем подписку на документ: ${idRootDocumentRentalPoint}`
      );

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV4({
              collectionPath: "rentalPoints",
              rootCurrentDocumenState: "selectedRentalPoint_rootData",
              idRootDocument: idRootDocumentRentalPoint,
              loadingStateName: "loading_RentalPoint",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписываемся при размонтировании компонента или изменении `idRootDocumentBike`
      return () => {
        unsubscribeRootDocument(idRootDocumentRentalPoint);
      };
    }
  }, [dispatch, mode, idRootDocumentRentalPoint]);

  /**
   *
   * Внутренняя логика компонента
   *
   */
  // const handleReplacementBike = (flag) => {
  //   const updatedOrder = updateUserChoiceFlag({
  //     order_selectedCompletedRootOrder: order_selectedCompletedRootOrder,
  //     indexBikeCurrentCart: indexBikeCurrentCart,
  //     keySlotReplacementBike: keySlotReplacementBike,
  //     indexReplacementBikes: indexReplacementBikes,
  //     flag: flag,
  //     oneOnly: true,
  //   });

  //   dispatch(
  //     setTempData({
  //       tempCurrentDocumentState: "currentUpdatedOrder",
  //       data: updatedOrder,
  //     })
  //   );

  //   onClose();
  //   closeParent();
  // };
  const addCart = () => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "orderedBike",
        data: selectedBike_rootData || null,
      })
    );
    setWindowOrderDetailModeration(true);
  };

  const handleReplacement = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "order_selectedCompletedRootOrder",
        data: updateBikeSlot({
          currentSlotIds: currentSlotIds,
          selectedBike_rootData: selectedBike_rootData,
          order_selectedCompletedRootOrder: order_selectedCompletedRootOrder,
          keySlotReplacementBike: keySlotReplacementBike,
        }),
      })
    );
    onClose();
    closeParent();
  };

  const handleClose = () => {
    setWindowOrderDetailModeration(false);
  };

  if (mode === "replacement" || mode === "order") {
    return (
      <>
        {/* POPUP WINDOWS */}
        <PopupDirrectory
          isOpen={windowOrderDetailModeration}
          onClose={handleClose}
          title="WindowOrderDetailModeration"
        >
          <WindowOrderDetailModeration
            mode="new"
            onClose={handleClose}
            closeParent={onClose}
          />
        </PopupDirrectory>
        {/* POPUP WINDOWS */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            paddingBottom: "72px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <ContentBox position="start" isLoading={loading.loading_bike}>
              <WidgetViewBikeDetail
                photoFiles={photoFiles}
                basicInformation={basicInformation}
                directory={directory}
                rate={rate}
                startBookingDates={startBookingDates}
                endBookingDates={endBookingDates}
              />
            </ContentBox>
            {/* Rental point data */}
            <ContentBox
              isLoading={loading.loading_RentalPoint}
              // position="start"
              header={
                <HeaderRentalPointPreview
                  rentalPointRootData={rentalPointRootData}
                  backgroundRentalPoint={
                    priorityFile(rentalPointRootData?.files?.photoFiles)?.url
                  }
                />
              }
            >
              <RentalPointData rentalPointRootData={rentalPointRootData} />
            </ContentBox>
          </div>
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "8px",
              }}
            >
              {mode === "replacement" && (
                <>
                  <Button
                    type="small"
                    active={true}
                    label="Cancel"
                    onClick={onClose}
                    color="--color-black"
                  />

                  <Button
                    type="small"
                    active={true}
                    label="Replacement"
                    // onClick={() => handleReplacementBike(true)}
                    onClick={handleReplacement}
                    allert={
                      <WidgetAlert
                        title={`Are you sure you want to buy "${orderedBikeTitle}" refund on "${replacementBike}"?`}
                        description={`Please remember that if the replacement offered is not exactly what the user ordered, it may reduce the chances that they will agree to rent a bike from you.`}
                      />
                    }
                  />
                </>
              )}
              {mode === "order" && (
                <>
                  <Button
                    type="small"
                    color="--color-black"
                    label="Cancel"
                    active={true}
                    onClick={onClose}
                  />
                  <Button
                    type="small"
                    label="Add cart"
                    active={true}
                    onClick={addCart}
                  />
                </>
              )}
            </div>
          </ButtonsBar>
        </div>
      </>
    );
  }

  /**
   *
   *  Выбор замены байка END
   *
   */
};

export default WindowViewBikeDetail;

const RentalPointData = ({ rentalPointRootData }) => {
  const isMobile = window.innerWidth <= 400;

  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div>
          {/* rental point name */}
          <h5>{rentalPointRootData?.basicInformation?.title?.titleOriginal}</h5>

          {/* location */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.pointAddress}
            </p>
          </div>
        </div>
      </div>
      {/* <WidgetListFileV4
          viewFiles={item?.rentalPointRootData?.files?.photoFiles || []}
          // loadingStateName=""
        /> */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          boxSizing: "border-box",
          gap: "2px",
        }}
      >
        {/* timetable */}
        <div
          style={{
            width: "100%",
            gridColumn: "1",
            gridRow: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            // gap: "16px",
            backgroundColor: "var(--color-gray-100)",
            boxSizing: "border-box",
            borderRadius: "16px",
            // aspectRatio: "1/1",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
              padding: "16px",
            }}
          >
            <h6
              style={{
                width: "100%",
                // color: "var(--color-gray-200)",
              }}
            >
              Timetable
            </h6>
            <span
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
              className="material-symbols-outlined"
            >
              alarm_on
            </span>
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: "2px",
              boxSizing: "border-box",
            }}
          >
            {rentalPointRootData?.timetable?.map((timetableItem, index) =>
              !timetableItem.isHoliday ? (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem.weekday.weekdayOriginal
                    )}
                  </p>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />

                  <div
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-success)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    <p>
                      {timetableItem?.openHours}:{timetableItem?.openMinutes}
                    </p>
                    —
                    <p>
                      {timetableItem?.closeHours}:{timetableItem?.closeMinutes}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem?.weekday?.weekdayOriginal
                    )}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-black)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    holiday
                  </p>
                </div>
              )
            )}
          </div>
        </div>

        {/* contacts */}
        {/* emails */}
        <div
          style={{
            gridColumn: "2",
            gridRow: "1",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.emailAddresses}
            fildname="email"
            title="Emails: "
            isMobile={isMobile}
            icon={
              <span
                // style={{
                //   color: "var(--color-gray-300)",
                // }}
                className="material-symbols-outlined"
              >
                mail
              </span>
            }
          />
        </div>
        {/* phones */}

        <div
          style={{
            gridColumn: "2",
            gridRow: "2",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.phoneNumbers}
            fildname="phone"
            title="Phones: "
            isMobile={isMobile}
            icon={
              <span
                // style={{
                //   color: "var(--color-gray-300)",
                // }}
                className="material-symbols-outlined"
              >
                call
              </span>
            }
          />
        </div>
      </div>
      {/* rental poiunt description */}
      {rentalPointRootData?.basicInformation?.description
        ?.descriptionOriginal && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>Description rental point</h6>
          <CollapsableText
            text={
              rentalPointRootData?.basicInformation?.description
                ?.descriptionOriginal || null
            }
          />
        </div>
      )}
    </div>
  );
};

const HeaderRentalPointPreview = ({
  rentalPointRootData,
  backgroundRentalPoint,
  title,
  address,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "160px",
        backgroundColor: "var(--color-gray-200)",
        position: "relative",
        backgroundImage: `url('${backgroundRentalPoint}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        padding: "16px 24px 42px 24px",
        boxSizing: "border-box",
        marginBottom: "48px",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "24px",
          bottom: "-48px",
        }}
      >
        <PriorityFile
          style={{
            borderRadius: "80px",
            height: "80px",
            width: "80px",
            border: "solid 4px var(--color-white)",
          }}
          files={rentalPointRootData?.files?.logoFiles}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px",
          position: "absolute",
          right: "24px",
          bottom: "calc(-24px - 8px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
              padding: "2px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.country?.title}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
              padding: "2px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.city?.title}
            </p>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <PriorityFile
          style={{
            borderRadius: "80px",
            height: "80px",
            width: "80px",
          }}
          files={rentalPointRootData?.files?.logoFiles}
        />
       
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "4px",
                height: "24px",
                padding: "0 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="p-light">
                {rentalPointRootData?.location?.country?.title}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "4px 0",
                height: "24px",
                padding: "0 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="p-light">
                {rentalPointRootData?.location?.city?.title}
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const ContactsList = ({ contacts, fildname, title, isMobile, icon }) => {
  const [showAll, setShowAll] = useState(false);

  const visibleContacts = contacts?.slice(0, 1);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: contacts?.length > 1 ? "space-between" : "flex-end",
        boxSizing: "border-box",
      }}
    >
      {Array.isArray(contacts) && contacts?.length > 1 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            // backgroundColor: "var(--color-gray-800)",
            padding: "16px",
          }}
        >
          <p
            className="p-light"
            // style={{
            //   color: "var(--color-gray-500)",
            // }}
          >
            {title && (
              <h6
              // style={{
              //   color: "var(--color-gray-200)",
              // }}
              >
                {title}
                {contacts.length}
              </h6>
            )}
          </p>
          {icon ? (
            <span
              onClick={() => setShowAll(true)}
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
            >
              {icon}
            </span>
          ) : (
            <span
              onClick={() => setShowAll(true)}
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
              className="material-symbols-outlined"
            >
              keyboard_arrow_right
            </span>
          )}
        </div>
      )}

      <div
        style={{
          width: "100%",
          padding: "0 16px 8px 16px",
          boxSizing: "border-box",
        }}
      >
        {visibleContacts?.map((contactItem, index) => (
          <div
            key={contactItem.key || index}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
              boxSizing: "border-box",
            }}
          >
            <p
              className="p-light"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "20ch" : "40ch",
                // color: "var(--color-gray-500)",
                boxSizing: "border-box",
              }}
            >
              {fildname && contactItem[fildname]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
