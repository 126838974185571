import React, { useEffect, useState } from "react";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import { v4 as uuidv4 } from "uuid";

import Button from "../WidgetsProfile/Buttons/Button";
import { dateFormat } from "../../../../../functions/convertDate";

const WidgetSelectReplacementBikeV4 = React.memo(
  ({ onClick, item, indexBikeCurrentCart, confirm }) => {
    console.log("WidgetSelectReplacementBikeV4 item", item);
    const [photoRootBike, setPhotoRootBike] = useState(null);
    const [titleRootbike, setTitleRootbike] = useState(null);
    const [quantityBikes, setQuantityBikes] = useState(null);
    const [titleRentalPoint, setTitleRentalPoint] = useState(null);
    const [replacementBikes, setReplacementBikes] = useState(null);
    const [quantityBike, setQuantityBike] = useState(null);
    const [dateInterval, setDateInterval] = useState(null);
    const [daysInInterval, setDaysInInterval] = useState(null);
    const [total, setTotal] = useState(null);
    const [rentalPointTitle, setRentalPointTitle] = useState(null);
    const [nonEmptyReplacementCount, setNonEmptyReplacementCount] = useState(0);
    const [emptyReplacementCount, setEmptyReplacementCount] = useState(0);
    const [brand, setBrand] = useState(null);
    const [category, setCategory] = useState(null);

    const [counter, setCounter] = useState(0); // общий счётчик

    useEffect(() => {
      if (item && item.bikeData) {
        setPhotoRootBike(item?.bikeData?.files?.photoFiles);
        setTitleRootbike(
          item?.bikeData?.basicInformation?.title?.titleOriginal
        );
        setTitleRentalPoint(
          item?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
            null
        );
        setQuantityBikes(item?.totals.quantity || null);
        setReplacementBikes(item?.replacementBikes || null);
        const countNonEmpty = Object.values(item?.replacementBikes).filter(
          (replacementItem) =>
            Array.isArray(replacementItem) && replacementItem.length > 0
        ).length;
        setNonEmptyReplacementCount(countNonEmpty);
        setQuantityBike(item?.totals?.quantity || null);
        setDateInterval(item?.totals?.dateInterval || null);
        setDaysInInterval(item?.totals?.daysInInterval || null);
        setTotal(item?.totals?.total || null);
        setRentalPointTitle(
          item?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
            null
        );
        const countEmpty = Object.values(item?.replacementBikes).filter(
          (replacementItem) =>
            Array.isArray(replacementItem) && replacementItem.length === 0
        ).length;
        setEmptyReplacementCount(countEmpty);
        setBrand(item?.bikeData?.directory?.brand?.title || null);
        setCategory(item?.bikeData?.directory?.category?.title || null);
      }
    }, [item]);

    const checkReplacementBikes = (replacementBikes) => {
      for (let key in replacementBikes) {
        if (Array.isArray(replacementBikes[key])) {
          if (replacementBikes[key].length === 0) {
            return true;
          }
        }
      }
      return false;
    };

    const checkUserChoice = (replacementItem) => {
      return replacementItem.some((bike) => bike.userChoice === true);
    };

    const selectedReplacementBike = (replacementItem) => {
      const selectedBike = replacementItem.find(
        (bike) => bike.userChoice === true
      );
      return selectedBike || null;
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          //   backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
          //   padding: "16px",
        }}
      >
        {/* Заказанный байк */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "8px",
            backgroundColor: "var(--color-gray-100)",
            padding: "16px",
            borderRadius: "16px 16px 0 0",
            // aspectRatio: "16/9",
            // borderRadius:
            //   Object.entries(replacementBikes).length === 1
            //     ? "16px"
            //     : `${
            //         indexBikeCurrentCart === 0
            //           ? "16px 16px 0 0"
            //           : indexBikeCurrentCart ===
            //             Object.entries(replacementBikes).length - 1
            //           ? "0 0 16px 16px"
            //           : "0"
            //       }`,
            // ...(isMobile ? { aspectRatio: "1/1" } : { aspectRatio: "16/9" }),

            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "4px",
            }}
          >
            <p
              className="p-light"
              style={{
                backgroundColor: "var(color-white)",
                padding: "2px 8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backdropFilter: "blur(10px)",
                // color: "var(--color-white)",
              }}
            >
              {brand}
            </p>

            <p
              className="p-light"
              style={{
                backgroundColor: "var(color-white)",
                padding: "2px 8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backdropFilter: "blur(10px)",
                // color: "var(--color-white)",
              }}
            >
              {category}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <h6
              style={{
                whiteSpace: "nowrap",
                // color: "var(--color-white)",
              }}
            >
              {titleRootbike}
            </h6>
            <p
              className="p-light"
              style={{
                // color: "var(--color-white)",
                opacity: "0.6",
              }}
            >
              {`You have ordered ${quantityBike} ${titleRootbike} bike${
                quantityBike > 1 ? "s" : ""
              }. However, the rental point "${rentalPointTitle}" has confirmed the availability of only ${emptyReplacementCount} bike${
                emptyReplacementCount > 1 ? "s" : ""
              }. They are offering ${nonEmptyReplacementCount} alternative option${
                nonEmptyReplacementCount > 1 ? "s" : ""
              } for replacement. Please review the list below and choose the most suitable option for you.`}
            </p>
          </div>
        </div>
        {Object.entries(replacementBikes || {})
          .sort(([keyA, replacementItemA], [keyB, replacementItemB]) => {
            if (replacementItemA.length === 0 && replacementItemB.length > 0) {
              return -1;
            }
            if (replacementItemA.length > 0 && replacementItemB.length === 0) {
              return 1;
            }
            return replacementItemA.length - replacementItemB.length;
          })

          .map(([key, replacementItem], index, array) => {
            const isLastElement = index === array.length - 1;
            // Если замены нет
            if (replacementItem.length === 0) {
              return (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                  key={uuidv4()}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "24px",
                        aspectRatio: "1/1",
                        borderRadius: "50%",
                        backgroundColor: "var(--color-success)",
                        color: "var(--color-white)",
                        right: "8px",
                        top: "8px",
                      }}
                    >
                      check_small
                    </span>

                    <div
                      style={{
                        position: "absolute",
                        left: "8px",
                        top: "8px",
                      }}
                    >
                      <h6
                        style={{
                          gridColumn: "span 2",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          backdropFilter: "blur(10px)",
                          color: "var(--color-white)",
                          padding: "2px 8px",
                          borderRadius: "4px",
                        }}
                        className="p-light"
                      >
                        {titleRootbike}
                      </h6>
                    </div>

                    <PriorityFile
                      style={{
                        width: "100%",
                        aspectRatio: "16/9",
                        borderRadius: isLastElement ? "0 0 16px 16px" : "0",
                      }}
                      files={photoRootBike}
                    />
                  </div>
                </div>
              );
            }

            // Если замена есть
            if (replacementItem.length > 0) {
              return (
                <div
                  key={uuidv4()}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                    }}
                  >
                    {checkUserChoice(replacementItem) ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {(() => {
                          const selectedBike =
                            selectedReplacementBike(replacementItem);

                          return (
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "absolute",
                                    width: "24px",
                                    aspectRatio: "1/1",
                                    borderRadius: "50%",
                                    backgroundColor: "var(--color-success)",
                                    color: "var(--color-white)",
                                    right: "8px",
                                    top: "8px",
                                  }}
                                >
                                  check_small
                                </span>
                                {!confirm && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "16px",
                                      left: "16px",
                                      right: "16px",
                                      display: "grid",
                                      flex: "1",
                                      boxSizing: "border-box",
                                      borderRadius: "80px",
                                    }}
                                  >
                                    <Button
                                      type="small"
                                      label="Change selection"
                                      active={true}
                                      color="--color-primary"
                                      onClick={() =>
                                        onClick({
                                          mode: "selected",
                                          indexBikeCurrentCart,
                                          indexReplacementBikes: index,
                                          keySlotReplacementBike: key,
                                        })
                                      }
                                    />
                                  </div>
                                )}

                                <div
                                  style={{
                                    position: "absolute",
                                    gap: "4px",
                                    left: "8px",
                                    top: "8px",
                                  }}
                                >
                                  <p
                                    style={{
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)",
                                      backdropFilter: "blur(10px)",
                                      color: "var(--color-white)",
                                      padding: "2px 8px",
                                      borderRadius: "4px",
                                      gridColumn: "span 2",
                                    }}
                                    className="p-light"
                                  >
                                    {
                                      selectedBike.basicInformation.title
                                        .titleOriginal
                                    }
                                  </p>
                                </div>

                                <PriorityFile
                                  style={{
                                    width: "100%",
                                    aspectRatio: "16/9",
                                    borderRadius: isLastElement
                                      ? "0 0 16px 16px"
                                      : "0",
                                  }}
                                  files={selectedBike?.files?.photoFiles}
                                />
                              </div>
                            </div>
                          );
                        })()}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            display: "grid",
                            gridTemplateColumns: `repeat(${replacementItem.length}, 1fr)`,
                            gap: "2px",
                            aspectRatio: "16/9",
                            width: "100%",
                            borderRadius: isLastElement ? "0 0 16px 16px" : "0",
                            overflow: "hidden",
                          }}
                        >
                          {replacementItem.map((bike, idx) => (
                            <div
                              key={uuidv4()}
                              style={{
                                boxSizing: "border-box",
                              }}
                            >
                              <PriorityFile
                                style={{
                                  boxSizing: "border-Box",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "0",
                                }}
                                files={bike?.files?.photoFiles}
                              />
                            </div>
                          ))}
                        </div>

                        {!confirm && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "16px",
                              left: "16px",
                              right: "16px",
                              display: "grid",
                              flex: "1",
                              boxSizing: "border-box",
                              borderRadius: "80px",
                            }}
                          >
                            <Button
                              type="small"
                              label="Change selection"
                              active={true}
                              color="--color-primary"
                              onClick={() =>
                                onClick({
                                  mode: "selected",
                                  indexBikeCurrentCart,
                                  indexReplacementBikes: index,
                                  keySlotReplacementBike: key,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            }

            return null;
          })}
      </div>
    );
  }
);

export default WidgetSelectReplacementBikeV4;
