import React, { useEffect, useState } from "react";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import Divide from "../WidgetsProfile/Divide/Divide";
import { truncateText } from "../../../../../functions/truncateText";

const WidgetBikesReplacmentListV4 = React.memo(
  ({
    onClick,
    onClickDelete,
    item,
    indexBikeCurrentCart,
    order_selectedCompletedRootOrder,
    isMobile,
    currentStateOrder,
  }) => {
    // const [photoRootBike, setPhotoRootBike] = useState(null);
    const [titleRootbike, setTitleRootbike] = useState(null);
    const [replacementBikes, setReplacementBikes] = useState(null);

    useEffect(() => {
      if (item && item.bikeData) {
        // setPhotoRootBike(item?.bikeData?.files?.photoFiles || null);
        setTitleRootbike(
          item?.bikeData.basicInformation?.title?.titleOriginal || null
        );
        setReplacementBikes(item?.replacementBikes || null);
      }
    }, [item]);

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // gap: "24px",
          boxSizing: "border-box",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
          padding: "16px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",

            // borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              alignItems: "center",
              gap: "16px",
              width: "100%",
            }}
          >
            {/* Фото */}
            <div
              style={{
                width: "100%",
                aspectRatio: "16/9",
              }}
            >
              <PriorityFile
                files={item.bikeData.files.photoFiles || []}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            {/* Название */}
            <h6>
              {truncateText(titleRootbike ? titleRootbike : "No name", 32)}
            </h6>
          </div>
          {/* <h6>{titleRootbike && titleRootbike}</h6> */}

          {Object.entries(replacementBikes || {}).map(
            ([key, bikeSlot], index) => (
              <>
                <div
                  key={key}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Divide title="Option" number={index + 1} />
                  <div
                    style={{
                      display: "grid",
                      // padding: "8px",
                      ...(isMobile
                        ? {
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gridTemplateRows: "repeat(2, 1fr)",
                          }
                        : {
                            gridTemplateColumns: "repeat(4, 1fr)",
                          }),
                      gap: "8px",
                      boxSizing: "border-box",
                    }}
                  >
                    {/* Проверка, является ли value массивом */}
                    {Array.isArray(bikeSlot) &&
                      bikeSlot.map((replacementItem, i) => (
                        <div key={i} style={{ position: "relative" }}>
                          <PriorityFile
                            style={{
                              borderRadius: "80px",
                              width: "100%",
                              height: "100%",
                              aspectRatio: "1/1",
                              gridColumn: `${index + 2}`,
                            }}
                            files={replacementItem?.files?.photoFiles}
                          />
                          {currentStateOrder !== "canceled" &&
                            currentStateOrder !== "replacement" &&
                            currentStateOrder !== "confirm" && (
                              <span
                                onClick={() =>
                                  onClickDelete({
                                    keySlotReplacementBike: key,
                                    indexBikeCurrentCart: indexBikeCurrentCart,
                                    indexDeleteSlotBike: index,
                                    indexDeletingBike: i,
                                    order_selectedCompletedRootOrder:
                                      order_selectedCompletedRootOrder,
                                  })
                                }
                                style={{
                                  ...(isMobile
                                    ? { padding: "8px" }
                                    : { padding: "4px" }),
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                  borderRadius: "50%",
                                  border: "0",
                                  backgroundColor: "var(--color-gray-100)",
                                  aspectRatio: "1/1",
                                  fontSize: "1rem",
                                  cursor: "pointer",
                                  boxSizing: "border-box",
                                }}
                                className="material-symbols-outlined"
                              >
                                close
                              </span>
                            )}
                        </div>
                      ))}

                    {/* Кнопка "+" для того чтобы добавить в массив замену */}

                    {currentStateOrder !== "canceled" &&
                      currentStateOrder !== "replacement" &&
                      currentStateOrder !== "confirm" &&
                      Array.isArray(bikeSlot) &&
                      bikeSlot.length !== 4 && (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            aspectRatio: "1/1",
                            borderRadius: "50%",
                            backgroundColor: "var(--color-alarm)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            color: "var(--color-white)",
                          }}
                          onClick={() =>
                            onClick({
                              mode: "replacement",
                              keySlotReplacementBike: key,
                              indexReplacementBike: index,
                              indexBikeCurrentCart: indexBikeCurrentCart,
                            })
                          }
                          className="material-symbols-outlined"
                        >
                          add
                        </div>
                      )}
                    {/* Создаём массив с помощью from для создания доступных ячеек  */}
                    {Array.from(
                      {
                        length:
                          (currentStateOrder !== "replacement" &&
                          currentStateOrder !== "canceled" &&
                          currentStateOrder !== "confirm"
                            ? 3
                            : 4) - bikeSlot.length,
                      },
                      (_, i) => (
                        <div
                          key={i}
                          style={{
                            width: "100%",
                            height: "100%",
                            aspectRatio: "1/1",
                            borderRadius: "50%",
                            backgroundColor: "var(--color-white)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxSizing: "border-box",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <h5 style={{ color: "var(--color-gray-300)" }}>
                              {i + bikeSlot.length + 2}
                            </h5>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    );
  }
);
export default WidgetBikesReplacmentListV4;
