import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import Button from "../WidgetsProfile/Buttons/Button";
import Table from "../WidgetsProfile/Table/Table";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/catalogSliceV4";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import WindowCatalogV4 from "./WindowCatalogV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import useIsMobile from "../../../../../functions/isMobile";
import WindowSelectedReplacementBike from "./WindowSelectedReplacementBike";
import {
  subscribeRootDocumentsThunkV4,
  unsubscribeRootDocument,
} from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribeRootDocumentsThunkV4";
import WidgetRentalPointDialogV4 from "./WidgetRentalPointDialogV4";
import WidgetClientDialogV4 from "./WidgetClientDialogV4";
import WidgetAlert from "../WidgetsProfile/Alerts/WidgetAlert";

const WindowNewOrderDetailV5 = React.memo(({ onClose }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { tempRootData, tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const renderCountRef = useRef(0);

  // Увеличиваем счетчик при каждом рендере
  useEffect(() => {
    renderCountRef.current += 1;
  });

  // ------- Windows stats
  const [windowCatalogV4, setWindowCatalogV4] = useState(false);
  const [windowSelectedReplacementBike, setWindowSelectedReplacementBike] =
    useState(false);

  // ------- tempRootData
  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);

  const [rootOrderStatus, setRootOrderStatus] = useState(null);
  const [userIdRootDocument, setUserIdRootDocument] = useState(null);
  const [currentCart, setCurrentCart] = useState(null);

  const [totalOrders, setTotalOrders] = useState(null);
  const [rentalPointRootData, setRentalPointRootData] = useState(null);
  const [rentalPointName, setRentalPointName] = useState(null);
  const [rentalPointLogoFile, setRentalPointLogoFile] = useState(null);

  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userAvatarUrls, setUserAvatarUrls] = useState(null);
  const [userName, setUserName] = useState(null);

  // ------- tempData
  const [orderIdRootDocument, setOrderIdRootDocument] = useState(null);
  const [currentOrderState, setCurrentOrderState] = useState(null);
  const [idUserCurrentOrder, setIdUserCurrentOrder] = useState(null);

  // ------- Внутренняя логика компоненета
  const [mode, setMode] = useState(null);
  const [replacementBox, setReplacementBox] = useState(false);
  const [waitUserBox, setWaitUserBox] = useState(false);
  const [currentStateOrder, setCurrentStateOrder] = useState(null);
  const [sendReplacements, setSendReplacements] = useState(false);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   * setReplacementBikes(item?.replacementBikes || null);
   *
   */

  useEffect(() => {
    setOrderIdRootDocument(
      tempData?.selectedCompletedOrderPreview?.idRootDocument || null
    );
    setIdUserCurrentOrder(
      tempData?.selectedCompletedOrderPreview?.client?.idRootDocument || null
    );
    setCurrentStateOrder(
      tempData?.selectedCompletedOrderPreview?.orderStatus || null
    );
  }, [tempData?.selectedCompletedOrderPreview]);

  useEffect(() => {
    setCurrentOrderState(
      tempRootData?.order_selectedCompletedRootOrder?.status?.currentStatus ||
        null
    );
    setRootOrderStatus(
      tempRootData?.order_selectedCompletedRootOrder?.status?.statusHistory ||
        null
    );
  }, [tempRootData?.order_selectedCompletedRootOrder]);

  // useEffect(() => {
  //   if (tempData?.currentUpdatedOrder) {
  //     setOrder_selectedCompletedRootOrder(
  //       tempData?.currentUpdatedOrder || null
  //     );
  //     setCurrentCart(tempData?.currentUpdatedOrder?.currentCart || null);
  //     setTotalOrders(tempData?.currentUpdatedOrder?.totals || null);
  //     setUserIdRootDocument(
  //       tempData?.currentUpdatedOrder?.client?.idRootDocument || null
  //     );

  //     if (
  //       tempData?.currentUpdatedOrder?.currentCart &&
  //       tempData?.currentUpdatedOrder?.currentCart.length > 0
  //     ) {
  //       setRentalPointRootData(
  //         tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData ||
  //           null
  //       );
  //       setRentalPointName(
  //         tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData
  //           ?.basicInformation?.title?.titleOriginal || null
  //       );
  //       setRentalPointLogoFile(
  //         tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData
  //           ?.files?.logoFiles || null
  //       );
  //     } else {
  //       setRentalPointRootData(null);
  //       setRentalPointName(null);
  //       setRentalPointLogoFile(null);
  //     }
  //   } else {
  //     setOrder_selectedCompletedRootOrder(
  //       tempRootData?.order_selectedCompletedRootOrder || null
  //     );
  //     setCurrentCart(
  //       tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
  //     );
  //     setTotalOrders(
  //       tempRootData?.order_selectedCompletedRootOrder?.totals || null
  //     );
  //     setUserIdRootDocument(
  //       tempRootData?.order_selectedCompletedRootOrder?.client
  //         ?.idRootDocument || null
  //     );

  //     if (
  //       tempRootData?.order_selectedCompletedRootOrder?.currentCart &&
  //       tempRootData?.order_selectedCompletedRootOrder?.currentCart.length > 0
  //     ) {
  //       setRentalPointRootData(
  //         tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
  //           ?.rentalPointRootData || null
  //       );
  //       setRentalPointName(
  //         tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
  //           ?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
  //           null
  //       );
  //       setRentalPointLogoFile(
  //         tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
  //           ?.rentalPointRootData?.files?.logoFiles || null
  //       );
  //     } else {
  //       setRentalPointRootData(null); // Или какое-то другое значение по умолчанию
  //     }
  //   }
  // }, [
  //   tempData?.currentUpdatedOrder,
  //   tempRootData?.order_selectedCompletedRootOrder,
  // ]);

  const checkReplacementBikes = (currentCart) => {
    return currentCart?.some((item) => {
      // Проверяем, что item.replacementBikes — это объект
      if (item.replacementBikes && typeof item.replacementBikes === "object") {
        // Проходим по всем ключам внутри replacementBikes и проверяем длину массивов
        return Object.values(item.replacementBikes).some(
          (array) => Array.isArray(array) && array.length > 0
        );
      }
      return false;
    });
  };

  useEffect(() => {
    if (tempData?.currentUpdatedOrder) {
      // setOrder_selectedCompletedRootOrder(
      //   tempData?.currentUpdatedOrder || null
      // );
      // setCurrentCart(tempData?.currentUpdatedOrder?.currentCart || null);
      // // Проверяем, есть ли замены для велосипедов
      // if (checkReplacementBikes(tempData?.currentUpdatedOrder?.currentCart)) {
      //   console.log(
      //     "There are replacement bikes available! 100",
      //     tempData?.currentUpdatedOrder?.currentCart
      //   );
      //   setSendReplacements(true);
      // } else {
      //   setSendReplacements(false);
      // }
      // setTotalOrders(tempData?.currentUpdatedOrder?.totals || null);
      // setUserIdRootDocument(
      //   tempData?.currentUpdatedOrder?.client?.idRootDocument || null
      // );
      // if (
      //   tempData?.currentUpdatedOrder?.currentCart &&
      //   tempData?.currentUpdatedOrder?.currentCart.length > 0
      // ) {
      //   setRentalPointRootData(
      //     tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData ||
      //       null
      //   );
      //   setRentalPointName(
      //     tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData
      //       ?.basicInformation?.title?.titleOriginal || null
      //   );
      //   setRentalPointLogoFile(
      //     tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData
      //       ?.files?.logoFiles || null
      //   );
      // } else {
      //   setRentalPointRootData(null);
      //   setRentalPointName(null);
      //   setRentalPointLogoFile(null);
      // }
    } else {
      setOrder_selectedCompletedRootOrder(
        tempRootData?.order_selectedCompletedRootOrder || null
      );
      setCurrentCart(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
      );
      // Проверяем, есть ли замены для велосипедов
      if (
        checkReplacementBikes(
          tempRootData?.order_selectedCompletedRootOrder?.currentCart
        )
      ) {
        console.log(
          "There are replacement bikes available! 200",
          tempRootData?.order_selectedCompletedRootOrder?.currentCart
        );
        setSendReplacements(true);
      } else {
        setSendReplacements(false);
      }
      setTotalOrders(
        tempRootData?.order_selectedCompletedRootOrder?.totals || null
      );
      setUserIdRootDocument(
        tempRootData?.order_selectedCompletedRootOrder?.client
          ?.idRootDocument || null
      );

      if (
        tempRootData?.order_selectedCompletedRootOrder?.currentCart &&
        tempRootData?.order_selectedCompletedRootOrder?.currentCart.length > 0
      ) {
        setRentalPointRootData(
          tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
            ?.rentalPointRootData || null
        );
        setRentalPointName(
          tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
            ?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
            null
        );
        setRentalPointLogoFile(
          tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
            ?.rentalPointRootData?.files?.logoFiles || null
        );
      } else {
        setRentalPointRootData(null);
      }
    }
  }, [
    tempData?.currentUpdatedOrder,
    tempRootData?.order_selectedCompletedRootOrder,
  ]);

  useEffect(() => {
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
    setUserId(tempRootData?.user_auth_rootCurrentData?.idRootDocument || null);
    setUserAvatarUrls(
      tempRootData?.user_auth_rootCurrentData?.files?.avatarUrls || null
    );
    setUserName(
      tempRootData?.user_auth_rootCurrentData?.user?.ownerName || null
    );
  }, [tempRootData?.user_auth_rootCurrentData]);

  /**
   *
   * Fetch Функции - с целью получения данных из slice
   *
   */

  useEffect(() => {
    if (orderIdRootDocument) {
      const collectionPath = "orders";
      const rootCurrentDocumenState = "order_selectedCompletedRootOrder";
      const idRootDocument = orderIdRootDocument;
      const loadingStateName = "loading_selectedCompletedRootOrder";
      const errorStateName = "currentError";

      dispatch(
        subscribeRootDocumentsThunkV4({
          collectionPath,
          rootCurrentDocumenState,
          idRootDocument,
          loadingStateName,
          errorStateName,
        })
      )
        .unwrap()
        .then((result) => {
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          console.error("Ошибка при подписке", error);
        });

      return () => {
        unsubscribeRootDocument(orderIdRootDocument); // Просто вызываем функцию отписки
        console.log("Отписка выполнена");
      };
    }
  }, [dispatch, orderIdRootDocument]);

  /**
   *
   * CRUD функции
   *
   */

  const applyData = useCallback(
    ({ stateOrder }) => {
      const previewReplacemtntOrderData = {
        ...tempData.selectedCompletedOrderPreview,
        orderStatus: stateOrder
          ? stateOrder
          : tempData.selectedCompletedOrderPreview.orderStatus,
      };

      const newStatusHistory = {
        status: stateOrder,
        timestamp: Date.now(),
        user: {
          userId: userId,
          userAvatarUrls: userAvatarUrls ? userAvatarUrls : [],
          userName: userName ? userName : "No name",
        },
      };

      const rootReplacemtntOrderData = {
        ...order_selectedCompletedRootOrder,
        status: {
          currentStatus: stateOrder,
          statusHistory: [
            ...(order_selectedCompletedRootOrder.status?.statusHistory || []),
            newStatusHistory,
          ],
        },
      };

      setCurrentStateOrder(stateOrder);

      dispatch(
        setTempData({
          tempCurrentDocumentState: "selectedCompletedOrderPreview",
          data: previewReplacemtntOrderData,
        })
      );

      dispatch(
        updateDataThunkV4({
          idRootDocument: orderIdRootDocument,
          rootData: rootReplacemtntOrderData,
          previewData: previewReplacemtntOrderData,
          rootCollectionPath: "orders",
          previewCollectionPath: `manager_ordersPreview.userIds.${userIdRootDocument}`,
          previewGeneralCollectionPath: `general_ordersPreview`,
          metadataDocumentPath: `manager_ordersPreview.userIds.${userIdRootDocument}.metadata`,
          metadataGeneralDocumentPath: `general_ordersPreview.metadata`,
          rootCurrentDocumentState: "order_selectedCompletedRootOrder",
          loadingStateName: "loading_selectedCompletedRootOrder",
          errorStateName: "currentError",
        })
      );
    },
    [
      tempData,
      userId,
      userAvatarUrls,
      userName,
      orderIdRootDocument,
      order_selectedCompletedRootOrder,
      dispatch,
      userIdRootDocument,
    ]
  );

  const handleClose = useCallback(() => {
    setWindowCatalogV4(false);
    setWindowSelectedReplacementBike(false);
  }, []);
  const handleReplacementBike = useCallback(
    ({
      mode,
      indexReplacementBike,
      indexBikeCurrentCart,
      keySlotReplacementBike,
    }) => {
      setMode(mode);

      dispatch(
        setTempData({
          tempCurrentDocumentState: "currentSlotIds",
          data: {
            indexReplacementBike,
            indexBikeCurrentCart,
            keySlotReplacementBike,
          },
        })
      );
      setWindowCatalogV4(true);
    },
    [dispatch]
  );

  const handleSelectedReplacementBike = useCallback(
    ({ mode, indexBikeCurrentCart, keySlotReplacementBike }) => {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "itemSlotSelectedReplacementBike",
          data: {
            indexBikeCurrentCart,
            keySlotReplacementBike,
          },
        })
      );

      setMode(mode);
      setWindowSelectedReplacementBike(true);
    },
    [dispatch]
  );

  const handleDeleteReplacementBike = useCallback(
    ({
      indexBikeCurrentCart,
      indexDeletingBike,
      keySlotReplacementBike,
      order_selectedCompletedRootOrder,
    }) => {
      const { currentCart } = order_selectedCompletedRootOrder;
      const cartItem = currentCart[indexBikeCurrentCart];

      if (
        cartItem.replacementBikes &&
        cartItem.replacementBikes.hasOwnProperty(keySlotReplacementBike)
      ) {
        const replacementArray =
          cartItem.replacementBikes[keySlotReplacementBike];

        const updatedReplacementArray = [
          ...replacementArray.slice(0, indexDeletingBike),
          ...replacementArray.slice(indexDeletingBike + 1),
        ];

        const updatedCartItem = {
          ...cartItem,
          replacementBikes: {
            ...cartItem.replacementBikes,
            [keySlotReplacementBike]: updatedReplacementArray,
          },
        };

        const updatedCurrentCart = [
          ...currentCart.slice(0, indexBikeCurrentCart),
          updatedCartItem,
          ...currentCart.slice(indexBikeCurrentCart + 1),
        ];

        const updatedOrder = {
          ...order_selectedCompletedRootOrder,
          currentCart: updatedCurrentCart,
        };

        dispatch(
          setCurrentRootData({
            rootCurrentDocumentState: "order_selectedCompletedRootOrder",
            data: updatedOrder,
          })
        );
      } else {
        console.log("Key with index", keySlotReplacementBike, "not found.");
      }
    },
    [dispatch]
  );

  const openReplacementBox = useCallback(() => {
    setReplacementBox(true);
  }, []);

  const closeReplacementBox = useCallback(() => {
    setReplacementBox(false);
  }, []);

  const openWaitUserBox = useCallback(
    ({ stateOrder }) => {
      setWaitUserBox(true);
      applyData({ stateOrder });
    },
    [applyData]
  );

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowCatalogV4}
        onClose={handleClose}
        title={`Replacement bike WindowCatalogV4`}
      >
        <WindowCatalogV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowSelectedReplacementBike}
        onClose={handleClose}
        title={`WindowSelectedReplacementBike`}
      >
        <WindowSelectedReplacementBike mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}

      {/*  position={
            currentOrderState === "confirm" || currentOrderState === "canceled"
              ? "end"
              : ""
          } */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom:
            currentOrderState === "confirm" || currentOrderState === "canceled"
              ? "0"
              : "72px",
        }}
      >
        {/* Диалог для RENTALPOINT */}
        {userId !== idUserCurrentOrder && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <WidgetRentalPointDialogV4
              userName={userName}
              userRole={userRole}
              userAvatarUrls={userAvatarUrls}
              rentalPointName={rentalPointName}
              rentalPointLogoFile={rentalPointLogoFile}
              rentalPointRootData={rentalPointRootData}
              currentCart={currentCart}
              totalOrders={totalOrders}
              replacementBox={replacementBox}
              waitUserBox={waitUserBox}
              order_selectedCompletedRootOrder={
                order_selectedCompletedRootOrder
              }
              handleReplacementBike={handleReplacementBike}
              handleDeleteReplacementBike={handleDeleteReplacementBike}
              currentStateOrder={currentStateOrder}
              rootOrderStatus={rootOrderStatus}
              isMobile={isMobile}
            />
          </div>
        )}

        {/* Диалог для CLIENT */}
        {userId === idUserCurrentOrder && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
              boxSizing: "border-box",
            }}
          >
            <WidgetClientDialogV4
              userName={userName}
              userRole={userRole}
              userAvatarUrls={userAvatarUrls}
              rentalPointName={rentalPointName}
              rentalPointLogoFile={rentalPointLogoFile}
              rentalPointRootData={rentalPointRootData}
              currentCart={currentCart}
              totalOrders={totalOrders}
              rootOrderStatus={rootOrderStatus}
              order_selectedCompletedRootOrder={
                order_selectedCompletedRootOrder
              }
              handleSelectedReplacementBike={handleSelectedReplacementBike}
              handleDeleteReplacementBike={handleDeleteReplacementBike}
              currentStateOrder={currentStateOrder}
              isMobile={isMobile}
            />
          </div>
        )}

        {/* Totals */}
        <ContentBox
          position={
            currentOrderState === "confirm" || currentOrderState === "canceled"
              ? "end"
              : ""
          }
        >
          <Table
            columns={1}
            title="Order totals"
            data={[
              {
                title: "Quantity of Bikes in Order:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {totalOrders?.quantity || 0}
                  </p>
                ),
              },
              {
                title: "Total cost additional services:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(
                      totalOrders?.totalCostAditionalServices || 0
                    )}
                  </p>
                ),
              },
              {
                title: "Total cost bike:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalOrders?.totalCostBike || 0)}
                  </p>
                ),
              },
              {
                title: "Total:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalOrders?.total || 0)}
                  </p>
                ),
              },
            ]}
          />
        </ContentBox>
      </div>

      {/* Панель кнопок для RENTALPOINT */}
      {userId !== idUserCurrentOrder &&
        currentOrderState !== "confirm" &&
        currentOrderState !== "canceled" && (
          <ButtonsBar>
            {!replacementBox && currentOrderState !== "replacement" && (
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <Button
                  type="small"
                  label="Replacement"
                  active={true}
                  onClick={openReplacementBox}
                  color="--color-alarm"
                  allert={
                    <WidgetAlert
                      title={`Are you sure you want to suggest a replacement to the user?`}
                      description="You'll have 4 slots to suggest replacements for each option the customer ordered. Just keep in mind, the customer might not accept the offer, and offering replacements could increase the chance of them rejecting the entire order."
                    />
                  }
                />
                <Button
                  type="small"
                  label="Confirm"
                  active={true}
                  onClick={() => applyData({ stateOrder: "confirm" })}
                />
              </div>
            )}

            {(replacementBox || currentOrderState === "replacement") && (
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <Button
                  type="small"
                  label="Cancel replacement"
                  active={true}
                  onClick={closeReplacementBox}
                  color="--color-black"
                />
                <Button
                  type="small"
                  label="Send replacements"
                  active={sendReplacements}
                  onClick={() => openWaitUserBox({ stateOrder: "replacement" })}
                  allert={
                    <WidgetAlert
                      title={`Are you sure you're ready to send the replacement options to the customer?`}
                      description="Please make sure to fill all available replacement option slots before submitting your offer. This will help increase the chances of the customer selecting a replacement."
                    />
                  }
                />
              </div>
            )}
          </ButtonsBar>
        )}

      {/* Панель кнопок для CLIENT */}
      {userId === idUserCurrentOrder &&
        currentOrderState !== "confirm" &&
        currentOrderState !== "canceled" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel order"
                active={true}
                onClick={() => applyData({ stateOrder: "canceled" })}
                color="--color-alarm"
                allert={
                  <WidgetAlert
                    title={`Are you sure you want to cancel your order?`}
                    description="If the order gets cancelled and you'd still like to pay for it, you'll just need to place the order again. No worries! Just keep in mind that the bike's price might change at that time, whereas the prices in your current order are locked in for 24 hours."
                  />
                }
              />
              <Button
                type="small"
                label="Confirm"
                active={true}
                onClick={() => applyData({ stateOrder: "confirm" })}
                // color="--color-black"
                allert={
                  <WidgetAlert
                    title={`Can you please confirm the order?`}
                    description="By confirming your order, you are booking the bikes for the selected date. If you want to learn more about the booking details, simply go to the 'Booking Terms' section on our website."
                  />
                }
              />
            </div>
          </ButtonsBar>
        )}
    </>
  );
});

export default WindowNewOrderDetailV5;
