import React, { useEffect, useState } from "react";
import "../profile.css";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import { useDispatch } from "react-redux";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import WindowBikeDetailModerationV4 from "../Moderation/WindowBikeDetailModerationV4";
import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";
import { setCurrentPreviewData } from "../../../../../reducersToolkit/catalogSliceV4";
import useIsMobile from "../../../../../functions/isMobile";
import Divide from "../WidgetsProfile/Divide/Divide";

import WindowViewBikeDetail from "./WindowViewBikeDetail";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  const monthName = date.toLocaleString("en-US", { month: "short" });
  return monthName;
};

const WidgetBikesList = ({
  mode,
  bikesList,
  bikeState,
  closeParent,
  dateInterval,
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  useState(false);
  const [bikesModerationList, setBikesModerationList] = useState([]);
  const [windowBikeDetailModeration, setWindowBikeDetailModeration] =
    useState(false);
  const [windowViewBikeDetail, setWindowViewBikeDetail] = useState(false);
  useState(false);

  /**
   *
   * Логика компонента
   *
   */

  const handleClose = () => {
    setWindowBikeDetailModeration(false);
    setWindowViewBikeDetail(false);
  };

  const handleToggleBikeDetail = (data) => {
    if (mode === "moderation") {
      setWindowBikeDetailModeration(true);

      dispatch(
        setCurrentPreviewData({
          currentPreviewDocumenState: "choosingBikeForModeration_previewData",
          data: data,
        })
      );
    } else if (mode === "replacement") {
      // Передаём данные для обработки их в WindowViewBikeDetail.js
      dispatch(
        setCurrentPreviewData({
          currentPreviewDocumenState:
            "choosingBikeToCreateReplacement_previewData",
          data: data,
        })
      );
      setWindowViewBikeDetail(true);
    } else if (
      mode === "order"
      //  || mode === "selected"
    ) {
      dispatch(
        setCurrentPreviewData({
          currentPreviewDocumenState: "choosingBikeForOrder_previewData",
          data: data,
        })
      );
      setWindowViewBikeDetail(true);
    }
  };

  useEffect(() => {
    if (bikesList && Array.isArray(bikesList)) {
      // Подсчет общего количества rentalPointsModeration и bikesModeration для каждого статуса
      // const totalsByStatus = bikesList.reduce(
      //   (acc, bike) => {
      //     const { status, totals } = bike;
      //     if (status && totals) {
      //       // Суммируем rentalPointsModeration
      //       if (totals.rentalPointsModeration != null) {
      //         acc[status] += totals.rentalPointsModeration;
      //       }
      //       // Суммируем bikesModeration
      //       if (totals.bikesModeration != null) {
      //         acc[status] += totals.bikesModeration;
      //       }
      //     }
      //     return acc;
      //   },
      //   { moderation: 0, approved: 0, banned: 0 }
      // );

      // Обновление options с компонентами
      // setOptions([
      //   {
      //     title: "moderation",
      //     additions: totalsByStatus?.moderation > 0 && (
      //       <QuantityParams param={totalsByStatus?.moderation} />
      //     ),
      //   },
      //   {
      //     title: "approved",
      //     additions: totalsByStatus?.approved > 0 && (
      //       <QuantityParams param={totalsByStatus?.approved} />
      //     ),
      //   },
      //   {
      //     title: "banned",
      //     additions: totalsByStatus?.banned > 0 && (
      //       <QuantityParams param={totalsByStatus?.banned} />
      //     ),
      //   },
      // ]);

      // Фильтрация списка пользователей по статусу
      if (bikesList && Array.isArray(bikesList)) {
        const filteredUsers = bikesList
          .filter((bike) => bike?.bikeStatus === bikeState)
          .sort((b, a) => new Date(a.lastUpdated) - new Date(b.lastUpdated));
        setBikesModerationList(filteredUsers);
      }
    }
  }, [bikeState, bikesList]);

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowBikeDetailModeration}
        onClose={handleClose}
        title="WindowBikeDetailModerationV4"
      >
        <WindowBikeDetailModerationV4
          mode={mode}
          onClose={handleClose}
          closeParent={closeParent}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowViewBikeDetail}
        onClose={handleClose}
        title="WindowViewBikeDetail"
      >
        <WindowViewBikeDetail
          mode={mode}
          onClose={handleClose}
          closeParent={closeParent}
        />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}

      <div className="profile-widget__content-box">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {bikesList?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                backgroundColor: "var(--color-white)",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "grid",
                  ...(isMobile
                    ? { gap: "16px", gridTemplateColumns: "repeat(1, 1fr)" }
                    : { gap: "8px", gridTemplateColumns: "repeat(2, 1fr)" }),

                  width: "100%",
                }}
              >
                {bikesModerationList?.map((bikeItem, index) => (
                  <>
                    {isMobile && (
                      <Divide
                        title="Bike"
                        number={`${bikeItem?.title} - ${index + 1}`}
                      />
                    )}

                    <BikeItemPreview
                      onClick={() => handleToggleBikeDetail(bikeItem)}
                      key={index}
                      itemKey={index}
                      month={bikeItem?.rate?.month}
                      bikeStatus={bikeItem?.bikeStatus}
                      title={bikeItem?.title}
                      rate={bikeItem?.rate}
                      photos={bikeItem?.fileUrl}
                      brand={bikeItem?.brand?.title}
                      category={bikeItem?.category?.title}
                      dateInterval={dateInterval}
                    />
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetBikesList;

const BikeItemPreview = ({
  month,
  photos,
  rate,
  title,
  descriptions,
  onClick,
  itemKey,
  brand,
  category,
  dateInterval,
}) => {
  const monthName = getMonthName(month);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  // Преобразуем интервал в номера месяцев
  const startMonth = dateInterval ? new Date(dateInterval[0]).getMonth() : null;
  const endMonth = dateInterval ? new Date(dateInterval[1]).getMonth() : null;

  // Находим максимальную цену
  if (!rate) {
    return;
  }
  const maxPrice = Math.max(...rate?.map((rateItem) => rateItem?.price));

  // Функция для расчета высоты блока
  const calculateHeight = (price) => {
    const maxHeight = 50;
    return (price / maxPrice) * maxHeight;
  };

  return (
    <div
      key={itemKey}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "8px",
        height: "100%",
        cursor: "pointer",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        <PhotosGallery photos={photos} onClick={onClick} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
            }}
          >
            <p className="p-light">{brand ? brand : "No brand"}</p>
          </div>

          <div
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
            }}
          >
            <p className="p-light">{category ? category : "No category"}</p>
          </div>
        </div>

        <h6>{title}</h6>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {rate?.map((rateItem, index) => {
          const isMonthInRange =
            startMonth !== null &&
            endMonth !== null &&
            index >= startMonth &&
            index <= endMonth;

          return (
            <div
              key={index}
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "2px",
              }}
            >
              {isMonthInRange && (
                <p
                  className="p-light"
                  style={{
                    fontSize: "0.6rem",
                  }}
                >
                  {getMonthName(index)}
                </p>
              )}

              <div
                title={`${getMonthName(index)} — ${formatCurrencyUSD(
                  rateItem?.price
                )}`}
                style={{
                  height: `${calculateHeight(rateItem.price)}px`,
                  minHeight: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "flex-end",
                  backgroundColor: isMonthInRange
                    ? "var(--color-success)"
                    : "var(--color-gray-100)",
                  ...(isMonthInRange
                    ? {
                        minHeight: "22px",
                        padding: "2px 4px",
                      }
                    : {}),
                }}
              >
                {isMonthInRange && (
                  <p
                    className="p-light"
                    style={{
                      width: "100%",
                      color: "var(--color-white)",
                      fontSize: "0.6rem",
                      textAlign: "center",
                      padding: "2px",
                    }}
                  >
                    {formatCurrencyUSD(rateItem?.price)}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
