import { initializeApp, getApps } from "firebase/app";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// const firebaseConfig = {
//   apiKey: "AIzaSyA9e476qujEfoSTz1VqtU7L-zmJIHRc9o0",
//   // authDomain: "biketripste.firebaseapp.com",
//   // authDomain: "localhost:3000",
//   authDomain: "biketripster.com",
//   databaseURL:
//     "https://biketripste-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "biketripste",
//   storageBucket: "biketripste.appspot.com",
//   messagingSenderId: "855588208493",
//   appId: "1:855588208493:web:1ebc65b32f6fac37476464",
//   measurementId: "G-RZZVLKC2Z9",
// };
const firebaseConfig = {
  apiKey: "AIzaSyA9e476qujEfoSTz1VqtU7L-zmJIHRc9o0",
  authDomain: "biketripster.com", // Пользовательский домен
  // authDomain: "192.168.31.140",
  projectId: "biketripste",
  storageBucket: "biketripste.appspot.com",
  messagingSenderId: "855588208493",
  appId: "1:855588208493:web:1ebc65b32f6fac37476464",
  measurementId: "G-RZZVLKC2Z9",
};

let app;

if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

// ********** Подключение эмулятора **********
// / Подключение к эмулятору Firestore
// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(db, "localhost", 8080);
// }

// // Подключение к эмулятору Authentication
// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://localhost:9099");
// }

// // Подключение к эмулятору Storage
// if (window.location.hostname === "localhost") {
//   connectStorageEmulator(storage, "localhost", 9199);
// }

// // Подключение к эмулятору Functions
// if (window.location.hostname === "localhost") {
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

// ********** Подключение эмулятора **********

export { db, storage, auth, functions };
