import { formatCurrencyUSD } from "../../../functions/currencyFormatter";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

const WidgetRate = ({
  rate = [], // Добавлено дефолтное значение для rate
  startBookingDates,
  endBookingDates,
  theme,
}) => {
  const startMonth = new Date(startBookingDates).getMonth();
  const endMonth = new Date(endBookingDates).getMonth();

  // Проверка, что rate — это массив
  const validRate = Array.isArray(rate) ? rate : [];

  const maxPrice = Math.max(...validRate.map((rateItem) => rateItem.price));
  const calculateHeight = (price) => (price / maxPrice) * 56;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
        }}
      >
        {validRate.map((rateItem, index) => (
          <div key={index} style={{ width: "100%" }}>
            {/* Выбранный тариф */}
            {index >= startMonth && index <= endMonth && (
              <div
                style={{
                  padding: "2px 0",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="p-light"
                  style={{
                    fontSize: "0.6rem",
                  }}
                >
                  {getMonthName(index)}
                </p>
              </div>
            )}
            {/* Остальные месяцы */}
            <div
              title={`${getMonthName(index)} — ${formatCurrencyUSD(
                rateItem?.price
              )}`}
              style={{
                height: `${calculateHeight(rateItem.price)}px`,
                width: "100%",
                borderRadius: "4px",
                backgroundColor:
                  index >= startMonth && index <= endMonth
                    ? "var(--color-success)"
                    : "var(--color-gray-100)",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "flex-end",
                ...(index >= startMonth && index <= endMonth
                  ? {
                      minHeight: "23px",
                      padding: "2px 4px",
                    }
                  : {
                      minHeight: "8px",
                      padding: "0",
                    }),
              }}
            >
              {index >= startMonth && index <= endMonth && (
                <p
                  className="p-light"
                  style={{
                    width: "100%",
                    color: "var(--color-white)",
                    fontSize: "0.6rem",
                    textAlign: "center",
                    padding: "2px",
                  }}
                >
                  {formatCurrencyUSD(rateItem?.price)}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetRate;

// import { formatCurrencyUSD } from "../../../functions/currencyFormatter";

// const getMonthName = (monthNumber) => {
//   const date = new Date(2000, monthNumber, 1);
//   return date.toLocaleString("en-US", { month: "short" });
// };

// const WidgetRate = ({ rate, startBookingDates, endBookingDates, theme }) => {
//   const startMonth = new Date(startBookingDates).getMonth();
//   const endMonth = new Date(endBookingDates).getMonth();
//   const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));
//   const calculateHeight = (price) => (price / maxPrice) * 56;
//   console.log("startMonth,endMonth", startMonth, endMonth);
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "8px",
//         width: "100%",
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "end",
//           justifyContent: "space-between",
//           gap: "2px",
//           width: "100%",
//         }}
//       >
//         {rate?.map((rateItem, index) => (
//           <div key={index} style={{ width: "100%" }}>
//             {/* Выбранный тариф */}
//             {index >= startMonth && index <= endMonth && (
//               <div
//                 style={{
//                   padding: "2px 0",
//                   width: "100%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "end",
//                   alignItems: "center",
//                   boxSizing: "border-box",
//                 }}
//               >
//                 <p
//                   className="p-light"
//                   style={{
//                     fontSize: "0.6rem",
//                   }}
//                 >
//                   {getMonthName(index)}
//                 </p>
//               </div>
//             )}
//             {/* Остальные месяцы */}
//             <div
//               title={`${getMonthName(index)} — ${formatCurrencyUSD(
//                 rateItem?.price
//               )}`}
//               style={{
//                 height: `${calculateHeight(rateItem.price)}px`,
//                 width: "100%",
//                 borderRadius: "4px",
//                 backgroundColor:
//                   index >= startMonth && index <= endMonth
//                     ? "var(--color-success)"
//                     : "var(--color-gray-100)",
//                 boxSizing: "border-box",
//                 display: "flex",
//                 alignItems: "flex-end",
//                 ...(index >= startMonth && index <= endMonth
//                   ? {
//                       minHeight: "23px",
//                       padding: "2px 4px",
//                     }
//                   : {
//                       minHeight: "8px",
//                       padding: "0",
//                     }),
//               }}
//             >
//               {index >= startMonth && index <= endMonth && (
//                 <p className="p-light" style={{ color: "var(--color-white)" }}>
//                   {formatCurrencyUSD(rateItem?.price)}
//                 </p>
//               )}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WidgetRate;
