import React from "react";
import { dateFormat } from "../../../../../../functions/convertDate";
import { truncateText } from "../../../../../../functions/truncateText";
import PriorityFile from "../UploadFiles/PriorityFile";

function checkUserAvatarUrls(userAvatarUrls) {
  if (Array.isArray(userAvatarUrls) && userAvatarUrls.length > 0) {
    return false;
  }
  if (typeof userAvatarUrls === "string" && userAvatarUrls.length > 0) {
    return false;
  }
  return true;
}

const UserData = ({
  type,
  children,
  date,
  userName,
  isMobile,
  userAvatarUrls,
}) => {
  if (type === "left") {
    return (
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            gridColumn: "span 10",
          }}
        >
          <p
            className="p-light"
            style={{
              padding: "8px 16px",
              width: "100%",
              textAlign: "left",
              boxSizing: "border-box",
              paddingRight: "16px",
            }}
          >
            {dateFormat(date)}
          </p>
          {children}
        </div>
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              aspectRatio: "1/1",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <PriorityFile
              isLoading={checkUserAvatarUrls(userAvatarUrls)}
              style={{
                borderRadius: "80px",
                width: "100%",
                aspectRatio: "1/1",
                backgroundColor: "var(--color-gray-100)",
              }}
              files={userAvatarUrls}
            />
          </div>
          <p className="p-light">
            {truncateText(userName || "No name", isMobile ? 5 : 10)}
          </p>
        </div>
      </div>
    );
  }

  if (type === "right") {
    return (
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: "8px",
        }}
      >
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              aspectRatio: "1/1",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <PriorityFile
              isLoading={checkUserAvatarUrls(userAvatarUrls)}
              style={{
                borderRadius: "80px",
                width: "100%",
                aspectRatio: "1/1",
                backgroundColor: "var(--color-gray-100)",
              }}
              files={userAvatarUrls}
            />
          </div>

          <p className="p-light">
            {truncateText(userName || "No name", isMobile ? 5 : 7)}
          </p>
        </div>
        <div
          style={{
            gridColumn: "span 10",
          }}
        >
          <p
            className="p-light"
            style={{
              padding: "8px 16px",
              width: "100%",
              textAlign: "right",
              boxSizing: "border-box",
            }}
          >
            {dateFormat(date)}
          </p>
          {children}
        </div>
      </div>
    );
  }

  return null;
};

export default UserData;
