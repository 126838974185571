import React, { useEffect, useState } from "react";
import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";
// import { v4 as uuidv4 } from "uuid";
// import WidgetBikeRate from "./WidgetBikeRate";
// import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
// import PhotosGallery from "../WidgetsProfile/PhotosGallery/PhotosGallery";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import Button from "../WidgetsProfile/Buttons/Button";
import WidgetRate from "../../../../Public/ProductCardV2/WidgetRate";

// const getMonthName = ({ monthNumber, mode }) => {
//   const date = new Date(2000, monthNumber, 1);

//   // Если mode === "trim", возвращаем сокращённое название месяца
//   const options = {
//     month: mode === "trim" ? "short" : "long",
//   };

//   const monthName = date.toLocaleString("en-US", options);
//   return monthName;
// };

const WidgetViewBikeDetail = ({
  photoFiles,
  basicInformation,
  directory,
  rate,
  startBookingDates,
  endBookingDates,
}) => {
  const [titleBike, setTitleBike] = useState(null);
  const [descriptionBike, setDescriptionBike] = useState(null);
  const [brandBike, setBrandBike] = useState(null);
  const [categoryBike, setCategoryBike] = useState(null);
  // const [startBookingDates, setStartBookingDates] = useState(0);
  // const [endBookingDates, setEndBookingDates] = useState(0);

  useEffect(() => {
    setDescriptionBike(
      basicInformation?.description?.descriptionOriginal || null
    );
    setTitleBike(basicInformation?.title?.titleOriginal || null);
    setBrandBike(directory?.brand?.title || null);
    setCategoryBike(directory?.category?.title || null);
  }, [basicInformation, directory]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <h5>{titleBike || "No bike name"}</h5>
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <WidgetListFileV4 isLoading={false} viewFiles={photoFiles} />
        <div
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              padding: "2px 8px",
              borderRadius: "4px",
              // height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-white)",
              }}
            >
              {categoryBike || "No selected catecory"}
            </p>
          </div>

          <div
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              padding: "2px 8px",
              borderRadius: "4px",
              // height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-white)",
              }}
            >
              {brandBike || "No selected brand"}
            </p>
          </div>
        </div>
      </div>
      {/* <BikeItemPreview rate={rate} dateInterval={orderDeteInterval} /> */}

      <WidgetRate
        startBookingDates={startBookingDates}
        endBookingDates={endBookingDates}
        rate={rate}
      />
      {descriptionBike && descriptionBike.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>{`Description ${titleBike}`}</h6>
          <CollapsableText text={descriptionBike} />
        </div>
      )}
    </div>
  );
};

export default WidgetViewBikeDetail;

// const BikeItemPreview = ({ dateInterval, rate }) => {
//   const currentDate = new Date();
//   const currentMonth = currentDate.getMonth();

//   if (!rate || !dateInterval || dateInterval.length !== 2) {
//     return null;
//   }

//   const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));

//   const calculateHeight = (price) => {
//     const maxHeight = 50;
//     return (price / maxPrice) * maxHeight;
//   };

//   // Получаем начало и конец диапазона
//   const startMonth = new Date(dateInterval[0]).getMonth();
//   const endMonth = new Date(dateInterval[1]).getMonth();
//   console.log("startMonth", startMonth);
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "end",
//         justifyContent: "space-between",
//         gap: "2px",
//       }}
//     >
//       <WidgetRate
//         startBookingDates={startMonth}
//         endBookingDates={endMonth}
//         rate={rate}
//       />
//       {rate.map((rateItem, index) => {
//         // Проверяем, входит ли месяц в диапазон
//         const isInDateInterval =
//           (startMonth <= endMonth &&
//             index >= startMonth &&
//             index <= endMonth) || // Диапазон в одном году
//           (startMonth > endMonth && (index >= startMonth || index <= endMonth)); // Диапазон через Новый год

//         return (
//           <div
//             style={{
//               width: "100%",
//               boxSizing: "border-box",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "flex-end",
//               gap: "4px",
//             }}
//           >
//             <div
//               title={`${getMonthName({
//                 monthNumber: index,
//               })} — ${formatCurrencyUSD(rateItem.price)}`}
//               key={index}
//               style={{
//                 height: `${calculateHeight(rateItem.price)}px`,
//                 minHeight: "8px",
//                 width: "100%",
//                 borderRadius: "4px",
//                 boxSizing: "border-box",
//                 display: "flex",
//                 alignItems: "flex-end",
//                 ...(isInDateInterval
//                   ? {
//                       minHeight: "22px",
//                       padding: "2px 4px",
//                       backgroundColor: "var(--color-success)",
//                     }
//                   : {
//                       backgroundColor: "var(--color-gray-100)",
//                     }),
//               }}
//             >
//               {isInDateInterval && (
//                 <p
//                   className="p-light"
//                   style={{
//                     color: "var(--color-white)",
//                   }}
//                 >
//                   {formatCurrencyUSD(rateItem.price)}
//                 </p>
//               )}
//             </div>

//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <p className="p-light">
//                 {getMonthName({ monthNumber: index, mode: "trim" })}
//               </p>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };
