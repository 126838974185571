function priorityFile(files) {
  console.log("function priorityFile", files);

  // Проверяем, что входной аргумент не undefined и не null
  if (files === undefined || files === null) {
    // console.error("Input is undefined or null");
    return null;
  }

  // Проверяем, что аргумент является массивом
  if (!Array.isArray(files)) {
    // console.error("Input should be an array, but received:", files);
    return null;
  }

  // Если массив не пустой, продолжаем логику
  if (files.length > 0) {
    // Ищем объект с флагом priority === true
    const priorityObject = files.find((file) => file.priority === true);

    // Если такой объект найден, возвращаем его, иначе возвращаем первый объект в массиве
    return priorityObject || files[0];
  } else {
    // console.error("Array is empty");
    return null;
  }
}

export default priorityFile;
