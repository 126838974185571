import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import Preloader from "../../../../../UIElements/Preloader";
import AddData from "../AddData/AddData";
import styled, { keyframes } from "styled-components";

const fadeInOut = keyframes`
  0% {
    background-color: rgba(128, 128, 128, 0.1);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.9);
  }
`;

const AnimatedDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInOut} 0.5s infinite alternate;
`;

const WidgetUploadFilesV4 = ({ isLoading, id, files, title, description }) => {
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [prevIsLoading, setPrevIsLoading] = useState(false);

  useEffect(() => {
    files(selectedFiles);
  }, [selectedFiles, files]);

  useEffect(() => {
    // Проверка на изменение isLoading с false -> true -> false
    if (prevIsLoading && !isLoading) {
      setSelectedFiles([]); // Очистка выбранных файлов после загрузки
    }
    // Обновляем предыдущее значение isLoading
    setPrevIsLoading(isLoading);
  }, [isLoading, prevIsLoading]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // max width
        800, // max height
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const removeFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const processFiles = async (files) => {
    setIsUploading(true); // Устанавливаем состояние загрузки

    const resizedFilesPromises = files.map((file) => {
      if (file.type.startsWith("image/") && file.size > 512 * 512) {
        return resizeFile(file);
      }
      return Promise.resolve(file);
    });

    const resizedFiles = await Promise.all(resizedFilesPromises);

    setSelectedFiles((prevFiles) => [...prevFiles, ...resizedFiles]);

    setIsUploading(false); // Сбрасываем состояние загрузки после завершения
  };

  return (
    <>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          backgroundColor: dragging
            ? "var(--color-priority)"
            : "var(--color-white)",
          width: "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          boxSizing: "border-box",
        }}
      >
        <input
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
          multiple
          id={`fileInput-${id}`}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          <AddData
            isLoading={isLoading}
            onClick={() => document.getElementById(`fileInput-${id}`).click()}
            icon={<span className="material-symbols-outlined">add</span>}
            title={title ? title : "Add photos"}
            description={description}
          />
          {selectedFiles.length > 0 && (
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                  selectedFiles && selectedFiles.length <= 3
                    ? selectedFiles.length
                    : 3
                }, 1fr)`,
                listStyleType: "none",
                gap: "8px",
                flex: "1",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {selectedFiles?.map((file, index) => (
                <li
                  key={index}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    ...(index === 2
                      ? {
                          gridColumn: "1 / span 2",
                          gridRow: "1 / span 2",
                        }
                      : {
                          aspectRatio: "16/9",
                        }),
                  }}
                >
                  {file.type.startsWith("image/") ? (
                    <div
                      className="container"
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(file)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        borderRadius: "8px",
                        overflow: "hidden",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {!isLoading && (
                        <span
                          className="material-symbols-outlined hidden-element"
                          onClick={() => removeFile(file)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            cursor: "pointer",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "white",
                          }}
                        >
                          close
                        </span>
                      )}
                      {isLoading && (
                        <AnimatedDiv>
                          <Preloader color="--color-white" />
                        </AnimatedDiv>
                      )}
                    </div>
                  ) : (
                    <span>{file.name}</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetUploadFilesV4;
